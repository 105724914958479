export default {
  fields: [
    {
      name: 'date',
      type: 'input',
      title: 'DateTime',
    },
    {
      name: 'intensiveExercise',
      type: 'input',
      title: 'Intensive Exercise',
    },
    {
      name: 'lightExercise',
      type: 'input',
      title: 'Light Exercise',
    },
    {
      name: 'moderateExercise',
      type: 'input',
      title: 'Moderate Exercise',
    },
    {
      name: 'restingHeartRate',
      type: 'input',
      title: 'Resting Heart Rate',
    },
    {
      name: 'source',
      type: 'input',
      title: 'Source',
    },
    {
      name: 'standing',
      type: 'input',
      title: 'Standing',
    },
    {
      name: 'steps',
      type: 'input',
      title: 'Steps',
    },
  ]
};
