export default {
  fields: [
    {
      name: 'date',
      type: 'input',
      title: 'DateTime',
    },
    {
      name: 'height',
      type: 'input',
      title: 'Height',
    },
    {
      name: 'weight',
      type: 'input',
      title: 'Weight',
    },
    {
      name: 'waist',
      type: 'input',
      title: 'Waist',
    },
    {
      name: 'arms',
      type: 'input',
      title: 'Upper Arms',
    },
    {
      name: 'chest',
      type: 'input',
      title: 'Chest',
    }
  ]
};