import { DropdownItems } from '../../constants/dropdowns';

export default {
  fields: [
    {
      name: 'dietType',
      type: 'select',
      data: 'dietTypes',
      title: 'Diet Type',
    },
    {
      name: 'exerciseLevel',
      type: 'select',
      data: 'exerciseLevels',
      title: 'Exercise Level',
    },
    {
      name: 'smoking',
      type: 'checkbox',
      title: 'Smoking',
    }
  ],
  dependencies: [
    {
      name: 'dietTypes',
      labelField: 'label',
      entries: DropdownItems.DietDropdown,
    },
    {
      name: 'exerciseLevels',
      labelField: 'label',
      entries: DropdownItems.ActivityLevelDropdown,
    }
  ]
};