export default {
  fields: [
    {
      name: 'date',
      type: 'input',
      title: 'DateTime',
    },
    {
      name: 'dose',
      type: 'input',
      title: 'Dose',
    },
    {
      name: 'longActing',
      type: 'input',
      title: 'Long-acting Insulin',
    },
    {
      name: 'fastActing',
      type: 'input',
      title: 'Insulin fast-acting',
    },
  ]
};
