const Dimensions = {
  DIMENSION_BUTTON_HEIGHT_LARGE: 60,
  DIMENSION_BUTTON_HEIGHT_MEDIUM: 50,
  DIMENSION_BUTTON_HEIGHT_SMALL: 30,
  DIMENSION_LEFT_MENU_WIDTH: 380,
  DIMENSION_LOGIN_FORM_WIDTH: 300,
  DIMENSION_INPUT_DEFAULT_HEIGHT: 56,
  DIMENSION_INPUT_DEFAULT_PADDING: '0 1rem',
  DIMENSION_INPUT_DEFAULT_FONTSIZE: 20,
  DIMENSION_OVERVIEW_CHART_SIZE: 100,
};

export default Dimensions;
