export default {
  fields: [
    {
      name: 'date',
      type: 'input',
      title: 'DateTime',
    },
    {
      name: 'burned',
      type: 'input',
      title: 'Burned',
    },
    {
      name: 'consumed',
      type: 'input',
      title: 'Consumed',
    },
    {
      name: 'carbohydrates',
      type: 'input',
      title: 'Carbohydrates',
    },
    {
      name: 'protein',
      type: 'input',
      title: 'Protein',
    },
    {
      name: 'fat',
      type: 'input',
      title: 'Fat',
    },
    {
      name: 'waterConsumption',
      type: 'input',
      title: 'Water consumption',
    },
  ]
};
