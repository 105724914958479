import { DropdownItems } from '../../constants/dropdowns';

export default {
  fields: [
    {
      name: 'date',
      type: 'input',
      title: 'DateTime',
    },
    {
      name: 'entered',
      type: 'input',
      title: 'Entered',
    },
    {
      name: 'foodId',
      type: 'select',
      data: 'foods',
      title: 'Food',
    },
    {
      name: 'type',
      type: 'select',
      data: 'types',
      title: 'Type',
    },
  ],
  dependencies: [ 
    {
      name: 'foods',
      labelField: 'label',
    },
    {
      name: 'types',
      labelField: 'label',
      entries: DropdownItems.FoodTypeDropdown,
    }
  ]
};