import {
  activityFormConfig,
  activityEntryFormConfig,
  anthropometryFormConfig,
  bloodPressureFormConfig,
  calorieFormConfig,
  cholesterolFormConfig,
  diabetesFormConfig,
  drugEntryFormConfig,
  exerciseFormConfig,
  foodFormConfig,
  foodEntryFormConfig,
  foodVariantFormConfig,
  glucoseDeviceFormConfig,
  glucoseManualFormConfig,
  insulinInjectionFormConfig,
  kidneyFormConfig,
  lifestyleFormConfig,
  medicineFormConfig,
  noteFormConfig,
  recipeFormConfig,
  stepFormConfig,
  userFormConfig,
  userBaselineFormConfig,
} from '../config/forms';

export const formConfigList = {
  'activity': activityFormConfig,
  'activityEntry': activityEntryFormConfig,
  'anthropometry': anthropometryFormConfig,
  'bloodPressure': bloodPressureFormConfig,
  'calorie': calorieFormConfig,
  'cholesterol': cholesterolFormConfig,
  'diabetes': diabetesFormConfig,
  'drugEntry': drugEntryFormConfig,
  'exercise': exerciseFormConfig,
  'food': foodFormConfig,
  'foodEntry': foodEntryFormConfig,
  'foodVariant': foodVariantFormConfig,
  'glucoseDevice': glucoseDeviceFormConfig,
  'glucoseManual': glucoseManualFormConfig,
  'insulinInjection': insulinInjectionFormConfig,
  'kidney': kidneyFormConfig,
  'lifestyle': lifestyleFormConfig,
  'medicine': medicineFormConfig,
  'note': noteFormConfig,
  'recipe': recipeFormConfig,
  'step': stepFormConfig,
  'userForm': userFormConfig,
  'userBaselineForm': userBaselineFormConfig,
};