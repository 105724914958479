export default {
  fields: [
    {
      name: 'date',
      type: 'input',
      title: 'DateTime',
    },
    {
      name: 'glucose',
      type: 'input',
      title: 'Glucose',
    },
  ]
};
