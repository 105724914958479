import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import { BrowserProtocol, queryMiddleware } from "farce";
import { createFarceRouter, createRender } from "found";
import { Resolver } from "found-relay";
import dotenv from "dotenv";

import i18n from "./i18n";

import routes from "./routes";
import environment from "./relay-environment";

import "./index.css";

dotenv.config();
process.env["NODE_TLS_REJECT_UNAUTHORIZED"] = 0;
const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],
  routeConfig: routes,
});

ReactDOM.render(
  <div>
    
    <Router resolver={new Resolver(environment)} />
  </div>,
  document.getElementById("root") || new Element()
  
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
