export default {
  "1 unit is enough for 3,7g sugar": "1 unit is enough for 3,7g sugar",
  "About Dialiv": "About Dialiv",
  "Accept terms of use": "Accept terms of use",
  "Activated markers": "Activated markers",
  "Active shares": "Active shares",
  "Activity level": "Activity level",
  Add: "Add",
  "Add range":"Add range",
  Administrator: "Administrator",
  Age: "Age",
  Amount: "Amount",
  and: "and",
  another: "another",
  "Another type": "Another type",
  auth_successful: "Successful authorization",
  auth_unsuccessful: "Unsuccessful authorization",
"Average":"Average",
  "Await provider registration": "Await provider registration",
  Description: "Description",
  "Desired weight":"Desired weight",
  Title: "Title",
  "Are you physically active": "Are you physically active",
  Back: "Back",
  "between you": "between you",
  "Blood sugar": "Blood sugar",
  Breakfast: "Breakfast",
  Burned: "Burned",
  Calculators: "Calculators",
  Cancel: "Cancel",
  Carbohydrates: "Carbohydrates",
  Calories:"Calories",
  "Carbohydrate counting": "Carbohydrate counting",
  Category: "Category",
  Change: "Change",
  "Choose date":"Choose date",
  Chat: "Chat",
  Clear: "Clear",
  Close: "Close",
  Coffee: "Coffee",
  Combustion: "Combustion",
  Comment: "Comment",
  "Completed shares": "Completed shares",
  confirm: "Confirm",
  confirm_password: "Confirm password",
  confirm_date: "Select a date first",
  create_a_new: "Create a new",
  Connected: "Connected",
  "Contains gluten": "Contains gluten",
  "Contains nuts": "Contains nuts",
  "Continuous glucose feeder (CGM)": "Continuous glucose feeder (CGM)",
  Conversation: "Conversation",
  "Convert blood sugar": "Convert blood sugar",
  Date: "Date",
  "Date of birth": "Date of birth",
  day: "day",
  "Days of combustion": "Days of combustion",
  Delete: "Delete",
  Diary: "Diary",
  Dinner: "Dinner",
  Consumed: "Consumed",
  Förbränt: "Burned",
  "Preferred diet":"Preferred diet",
  Finished: "Finished",
  From: "From",
  Frukost:"Breakfast",
  LunchSnack:"Lunch/Snack",
  Lunch:"Lunch",
  Bröst: "Chest",
  Ätit: "Eaten",
  Distribution: "Distribution",
  "General diabetes control": "General diabetes control",
  "Do you eat any special diet": "Do you eat any special diet",
  "Do you have diabetes": "Do you have diabetes",
  "Do you smoke": "Do you smoke",
  "Do you use any of the following": "Do you use any of the following",
  "Do you want to improve your blood sugar":
    "Do you want to improve your blood sugar",
  "Do you want to improve your risk factors":
    "Do you want to improve your risk factors",
  "Do you want to lose weight": "Do you want to lose weight",
  Doses: "Doses",
  Done: "Done",
  Done: "Done",
  Drink: "Drink",
  duplicate_email: "Email already registered",
  duplicate_sharing: "You are already sharing data with this provider",
  "E-mail": "E-mail",
  eat: "eat",
  Eaten: "Eaten",
  Energy: "Energy",
  "Enter amount": "Enter amount",
  "Enter target range": "Enter target range",
  Example: "Example: ",
  "Example Apple Watch, Fitbit": "Example: Apple Watch, Fitbit",
  "Example Glucomen": "Example: Glucomen",
  "Example Libre": "Example: Freestyle Libre, Dexcom",
  Exercise: "Exercise",
  "Exercises easily 1 to 3 days / week": "Exercises easily 1 to 3 days / week",
  "Exercise daily, or intensely 3 to 4 days / week":
    "Exercise daily, or intensely 3 to 4 days / week",
  "Exercises intensively 6 to 7 days / week":
    "Exercises intensively 6 to 7 days / week",
  "Exercises moderately 4 to 5 days / week":
    "Exercises moderately 4 to 5 days / week",
  "Exercise very intensively daily or have a physically demanding job":
    "Exercise very intensively daily or have a physically demanding job",
  Fat: "Fat",
  first_name: "First name",
  Food: "Food",
  "Food & Calories": "Food & Calories",
  for: "for",
  "Forgot password?": "Forgot password?",
  "Gestational diabetes": "Gestational diabetes",
  gestational: "gestational",
  glucose: "glucose",
  "Glucose Strips": "Glucose Strips",
  "Health Markers": "Health Markers",
  "Mått runt bröstkorg":"Measurement around chest",
  health_care_provider_relative: "Health care provider/relative",
  "Here you can comment and keep a logbook":
    "Here you can comment and keep a logbook",
  "Here your values are prefilled. You can change the values if you want.":
    "Here your values are pre-filled. You can change the values if you want.",
  Height: "Height",
  hours: "hours",
  "How tall are you": "How tall are you",
  "How much do you weigh": "How much do you weigh",
  "How much insulin do you take each day":
    "How much insulin do you take each day",
  "How much should I eat per day": "How much should I eat per day",
  "I do not exercise, or very little": "I do not exercise, or very little",
  "I eat a regular diet": "I eat a regular diet",
  'If you click on "About us" or "Terms, policy" you will come to a static page with information':
    'If you click on "About us" or "Terms, policy ..." you will come to a static page with information',
  "If you do not want to lose weight, you can enter your current weight to calculate the number of calories you need to maintain your weight. If you want to lose weight, aim to 0.5 to 1 kg per week.":
    "If you do not want to lose weight, you can enter your current weight to calculate the number of calories you need to maintain your weight. If you want to lose weight, aim to 0.5 to 1 kg per week.",
  Ingredients: "Ingredients",
  Installing: "Installing",
  "I accept the user agreement": "I accept the user agreement",
  "Insulin Pump": "Insulin Pump",
  Instructions: "Instructions",
  Intensive: "Intensive",
  "Intermittent fasting": "Intermittent fasting",
  invalid_credentials: "Invalid credentials",
  Iron: "Iron",
  last_name: "Last name",
  left: "left",
  Length: "Length",
  Nej:"No",
  "Log in": "Log in",
  "Low carbohydrate diet": "Low carbohydrate diet",
  login: "Login",
  Lunch: "Lunch",
  malformed_email: "Incorrect email format",
  Man: "Man",
  Medicine: "Medicine",
  Menu: "Menu",
  Minerals: "Minerals",
  minutes: "minutes",
  "Mobile number": "Mobile number",
  Moderate: "Moderate",
  "Messages":"Messages",
  "My medicines": "My medicines",
  "My profile": "My profile",
  Name: "Name",
  "New dose": "New dose",
  "New message": "New message",
  "New note": "New note",
  "New password": "New password",
  Code: "Enter Code",
  omvandla_glukos: "Convert glucose",
  "Overview":"Overview",
  Glukos: "Glucose",
  Next: "Next",
  No: "No",
  no_unread_msgs: "No unread messages",
  "Not connected": "Not connected",
  not_accept_terms: "Please accept the terms of use",
  "Number of steps": "Number of steps",
  "Nutritional Information": "Nutritional Information",
  Ongoing: "Ongoing",
  "Paired devices": "Paired devices",
  Password: "Password",
  People: "People",
  Person: "Person",
  invalid_phone_nr: "Invalid phone number format",
  "The password needs to be atleast 8 characters long":
    "The password needs to be atleast 8 characters long",
  "Physical activity": "Physical activity",
  portions: "portions",
  "Preferred diet": "Preferred diet",
  Protein: "Protein",
  grant_permissions: "Please grant permission for sharing data in appsettings",
  provide_code: "Provide verification code",
  invalid_email_or_password: "Invalid credentials",
  invalid_first_name: "Invalid first name",
  invalid_last_name: "Invalid last name",
  invalid_password_length: "The password must have at least 8 characters",
  invalid_verification_code: "Invalid verification code",
  Recept: "Recept",
  recipes: "Recipes",
  Recipe: "Recipe",
  Register: "Register",
  "Registered values":"Registered values",
  Registrations: "Registrations",
  "Reminders at each dose": "Reminders at each dose",
  Remove: "Remove",
  "Repeat password": "Repeat password",
  "Reset password": "Reset password",
  Results: "Results",
  return_to_login: "Return to login",

  rule: "rule",
  Save: "Save",
  "Send one-time code": "Send one-time code",
  "Select calculator": "Select calculator",
  "Select gender": "Select gender",
  "Select health marker": "Select health marker",
  Send: "Send",
  send_email: "Send email",
  Sex: "Sex",
  Vikt:"Weight",
  Shares: "Shares",
  "Sharings":"Sharings",
  "Share in interval": "Share in interval",
  Snack: "Snack",
  Specify: "Specify",
  Standing: "Standing",
  "Static Info": "Static Info",
  code_verification_success: "Successfully verified, please login to continue",
  "Target weight": "Target weight",
  "Taken when necessary": "Taken when necessary",
  "Tell me a little about you": "Tell me a little about you",
  "Terms, Policy & Information Security":
    "Terms, Policy & Information Security",
  "Today's activities": "Today's activities",
  "Today's intake": "Today's intake",
  "To maintain your current weight": "To maintain your current weight",
  "To reach the goals": "To reach the goals",
  to: "to",
  Totally: "Totally",
  type: "type",
  "Type 1 diabetes": "Type 1 diabetes",
  "Type 2 diabetes": "Type 2 diabetes",
  units: "units",
  value: "value",
  Username: "Username",
  "User":"User",
  Logout:"Log out",
  Vegetarian: "Vegetarian",
  verification_code_info_sent:
    "We have sent a verification code to your email. Please enter it below.",
  verify_code: "Verify",
  we_sent_email: "We have sent an email to you",
  Favorite: "Favorite",
  "View favorites": "View favorites",
  Walk: "Walk",
  Water: "Water",
  week: "week",
  weeks: "weeks",
  Weeks: "Weeks",
  "Weight planning": "Weight planning",
  "Weight & metabolism": "Weight & metabolism",
  "What do you want to add": "What do you want to add",
  "What kind of diabetes do you have": "What kind of diabetes do you have",
  "which is": "which is",
  "What is your target weight": "What is your target weight",
  "What type of diabetes do you have": "What type of diabetes do you have",
  "When were you born": "When were you born",
  "When do you want to reach your target weight":
    "When do you want to reach your target weight",
  "When did the treatment begin?": "When did the treatment begin?",
  "Which do you use": "Which do you use",
  "Which insulin pump do you use": "Which insulin pump do you use",
  Woman: "Woman",
  "Write here": "Write here",
  Years: "Years",
  Yes: "Yes",
  You: "You",
  "You need to reduce your calorie intake by ":
    "You need to reduce your calorie intake by ",
  "You reach the goal if you eat": "You reach the goal if you eat",
  "Your current weight": "Your current weight",
  "Your password is now changed!": "Your password is now changed!",
  logout: "Logout",
  "Change Password": "Change Password",
  "My Account": "My Account",
  "My account":"My account",
  "Share data": "Share Data",
  "Share data and chat": "Share data and chat",
  "New Sharing": "New Sharing",
  email: "E-mail",
  phone: "Phone Number",
  Chest: "Chest",
  Provider: "Provider",
  addSharingDescription:
    "Here you can add people who can see your data. The person you share data with gets an invitation via email. You can delete one sharing at any time.",
  shareDataDashboardDescription:
    "You can share your personal data. You are responsible for the decision to share your data and so that the right person has access to your data.",
  share_with_dialiv_free:
    "Share your data and converse with one of our doctors. It is free of charge.",
  iHaveACode: "I have a code",
  "Glukos, slumpmässigtvärde": "Glucose, random value",
  "Glukos, fasta": "Glucose, fast",
  "Glukos, innan frukost": "Glucose, before breakfast",
  "Glukos, efter frukost": "Glucose, after breakfast",
  "Glukos, innan lunch": "Glucose, before lunch",
  "Glukos, efter lunch": "Glucose, after lunch",
  "Glukos, innan middag": "Glucose, before dinner",
  "Glukos, efter middag": "Glucose, after dinner",
  "Glucose, after dinner":"Glucose, after dinner",
  HbA1c: "HbA1c",
  "LDL kolesterol": "LDL colesterol",
  "Total kolesterol": "Total colesterol",
  "HDL kolesterol": "HDL colesterol",
  Triglycerider: "Triglycerides",
  Weight: "Weight",
  Midjemått: "Waistmeasurement",
  BMI: "BMI",
  "Sharing start": "Sharing start",
  "Sharing status": "Sharing status",
  diabetes_type: "Type of diabetes",
  "Genomsnittligt glukos": "Average glucose",
  "Mått runt armar och bröstkorg": "Measurements around arms and chest",
  "Mått runt armar":"Measurement around arms",
  "Measurements around arms and chest": "Measurements around arms and chest",
  "Förbränning (kcal), totalt": "Burning (kcal), in total",
  "Ätit (kcal),totalt": "Eaten (kcal), in total",
  Kolhydrater: "Carbohydrates",

"Pre-diabetes": "Pre-diabetes",
"Dont have diabetes":"Don’t have diabetes",
"Obesity/Overweight": "Obesity/Overweight",
  Protein: "Protein",
  Fett: "Fat",
  "Water consumption": "Water consumption",

  Vattenkonsumption: "Water consumption",
  "U-albumin/U-kreatinin": "U-albumin/U-kreatinin",
  Kreatinin: "Kreatinin",
  eGFR: "eGFR",
  "Systoliskt blodtryck": "Systolic bloodpressure",
  "Diastoliskt blodtryck": "Diastolic bloodpressure",
  Diastolic: "Diastolic",
  Insulin: "Insulin",
  Systolic: "Systolic",
  "Your comment": "Your comment",
  Omvandla: "Convert",
  "Long-acting Insulin": "Long-acting Insulin",
  "Insulin fast-acting": "Insulin fast-acting",
  "Långverkande insulin": "Long-acting Insulin",
  "Snabbverkande insulin ": "Insulin fast-acting",
  Steg: "Steps",
  Träning: "Training",
  Training: "Training",
  "Smartwatch":"Smartwatch",
  Hjärtfrekvens: "Heartfrequency",
  "Stress från smartwatch": "Stress from smartwatch",
  "Sömn från smartwatch": "Sleep from smartwatch",
  "Ange antalet enheter som ska beräknas":
    "Input the amount of units that should be calculated",
  "Stress from smartwatch": "Stress from smartwatch",
  "Sleep from smartwatch": "Sleep from smartwatch",
  GLUKOS: "GLUCOSE",
  NUTRITION: "NUTRITION",
  LIFESTYLE: "LIFESTYLE",
  LÄKEMEDEL: "MEDICINE",
  ANTROPOMETRI: "ANTHROPOMETRY",
  "HJÄRTA, KÄRL & NJURAR": "HEART, VESSELS & KIDNEYS",
  NoData: "No data",
  Klar: "Done",
  Systoliskt: "Systolic",
  Diastoliskt: "Diastolic",

  'highBloodPressure': 'High blood pressure',
  'bloodFatDisorder': 'Blood fat disorder',
  'exerciseHabits': 'Exercise habits',
  'dietaryHabits': 'Dietary habits',
  'obesityOverweight': 'Obesity/Overweight',

"Pre-diabetes": "Pre-diabetes",
"Dont have diabetes":"Don’t have diabetes",
"Obesity/Overweight": "Obesity/Overweight",

tell_us_about_you: 'Tell us a little bit about you',
  had_complications: 'Have you had any complications?',
  got_complications:"Got complications?",
  complications:"Complications",
  desire_help_with:"Wants help with",
  diabetes_duration:"Diabetes duration",
  diabetes_treatment:"Diabetes treatment",
  developed_diabetes:"When was your diabetes developed?",
  your_diabetes_treatment:"What is your diabetes treatment?",
  your_diabetes_worry:"How worried are you for your diabetes?",
  worry_level:"Worry level",
  months: 'months',
  year: 'year',

  dietDifficulties:"Difficulties changing diet",
  insufficientExercise:"Insufficient exercise",
  stress:"Stress",
  lackOfSupport: "Lack of support",
  lackOfKnowledge:"Insufficient knowledge to deal with my diabetes",
  noneMentioned:"None of what is mentioned above",
  Insulin: 'Insulin',
  required:"required",
  active:"Active",
  "Type of meal":"Type of meal"
};