import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../relay-environment";

const mutation = graphql`
  mutation CreateRecipeMutation($input: NewRecipeInput!) {
    createRecipe(newRecipeInput: $input) {
      _id: id
    }
  }
`;

export default function CreateRecipeMutation(input) {
  // flow-disable

  function tryParse(input) {
    var res = parseFloat(input);

    if (res == NaN) {
      return false;
    } else {
      return true;
    }
  }
  return new Promise((resolve, reject) => {
    input = {
      carbFiberG: tryParse(input.carbFiberG)
        ? parseFloat(input.carbFiberG)
        : null,
      carbStarchG: tryParse(input.carbStarchG)
        ? parseFloat(input.carbStarchG)
        : null,
      carbTotalG: tryParse(input.carbTotalG)
        ? parseFloat(input.carbTotalG)
        : null,
      cholesterolMg: tryParse(input.cholesterolMg)
        ? parseFloat(input.cholesterolMg)
        : null,
      cookTime: tryParse(input.cookTime) ? parseFloat(input.cookTime) : null,
      description: input.description,
      imgDispName: input.imgDispName,
      imgUrl: input.imgUrl,
      ingredients: input.ingredients,
      instructions: input.instructions,
      kcalPerServing: tryParse(input.kcalPerServing)
        ? parseFloat(input.kcalPerServing)
        : null,
      prepTime: tryParse(input.prepTime) ? parseFloat(input.prepTime) : null,
      proteinG: tryParse(input.proteinG) ? parseFloat(input.proteinG) : null,
      recipeCategoryId: tryParse(input.recipeCategoryId)
        ? parseFloat(input.recipeCategoryId)
        : null,
      saturatedFatG: tryParse(input.saturatedFatG)
        ? parseFloat(input.saturatedFatG)
        : null,
      servings: tryParse(input.servings) ? parseFloat(input.servings) : null,
      sodiumMg: tryParse(input.sodiumMg) ? parseFloat(input.sodiumMg) : null,
      sugarsG: tryParse(input.sugarsG) ? parseFloat(input.sugarsG) : null,
      taxonomyCookingStyle: input.taxonomyCookingStyle,
      taxonomyCuisine: input.taxonomyCuisine,
      taxonomyDietHealth: input.taxonomyDietHealth,
      taxonomyDishType: input.taxonomyDishType,
      taxonomyGluten: input.taxonomyGluten,
      taxonomyIngredients: input.taxonomyIngredients,
      taxonomyMealType: input.taxonomyMealType,
      taxonomyNuts: input.taxonomyNuts,
      title: input.title,
      totalFatG: tryParse(input.totalFatG) ? parseFloat(input.totalFatG) : null,
      totalTime: tryParse(input.totalTime) ? parseFloat(input.totalTime) : null,
    };
    const variables = {
      input,
    };
    commitMutation(environment, {
      mutation,
      variables,
      onError: (err) => reject(err),
    });
  });
}
