import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./config/locales/en.js";
import sv from "./config/locales/sv.js";


i18n.use(LanguageDetector).use(initReactI18next).init({
  resources: {
    en: { translation: en },
    sv: { translation: sv },
  },
  lng:  navigator.language,

  fallbackLng: "sv",
  // debug only when not in production
  debug: process.env.NODE_ENV !== "production",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },
  react: {
    wait: true,
  },
});

export default i18n;
