import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import i18n from "../../../../i18n";

import {
  MainInput,
  Link,
  PrimaryButton,
  PrimaryText,
  WhiteText,
} from "../../../../components";
import { Dimensions, Images } from "../../../../styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  checkbox: {
    marginBottom: "2rem",
  },
  content: {
    width: Dimensions.DIMENSION_LOGIN_FORM_WIDTH,
    textAlign: "center",
  },
  input: {
    marginBottom: "2rem",
  },
  loginButton: {
    marginBottom: "2rem",
  },
  logo: {
    margin: "2rem 0",
  },
  errorDiv: {
    color: "red",
    marginTop: "-1rem",
    marginBottom: "1rem",
  },
}));

// for testing purpose use this token
// xxx/provider/register?token=Z29tYXJjLmNoZW5AZ21haWwuY29tJlgmMjI=
const Register = (props: Object) => {
  const classes = useStyles();
  const [state, setState] = useState({
    email: "",
    password: "",
    token: "",
    sharingId: "",
    acceptTerms: false,
    firstName: "",
    lastName: "",
  });
  const token = props.match.location.query.token;
  const [isValidCredentials, setIsValidCredentials] = useState(null);
  const [isValidPassword, setIsValidPassword] = useState(null);

  if (!token) {
    props.router.push("/provider/login");
  }

  useEffect(() => {
    if (token) {
      const decodedToken = atob(token);
      const [email, name, sharingId] = decodedToken.split("&");
      var firstName = "";
      var lastName = "";
      const nameParts = name.split(" ");
      if (nameParts.length == 2) {
        firstName = nameParts[0];
        lastName = nameParts[1];
      }

      // lastName = "eee";
      setState((prevState) => ({
        ...prevState,
        email: email,
        firstName: firstName,
        lastName: lastName,
        sharingId: sharingId,
      }));
    }
  }, []);

  const handleChangeInput = (key, value) => {
    if (key === "password") {
      if (value.length < 8) {
        setIsValidPassword(false);
      } else {
        setIsValidPassword(true);
      }
    }
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleClickRegister = () => {
    const { email, firstName, lastName, password } = state;

    setState((prevState) => ({
      ...prevState,
      isActivityIndicatorVisible: true,
    }));

    const user = {
      firstName,
      lastName,
      email: email.trim(),
      password,
      roleId: 3,
    };

    fetch(process.env.REACT_APP_REST_API_URL + "/users", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Role: "PROVIDER",
        SharingToken: token,
      },
      body: JSON.stringify(user),
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const error = await res.json();

          setState((prevState) => ({
            ...prevState,
            isActivityIndicatorVisible: false,
          }));
          throw Error(error.error.message);
        }
      })
      .then((res) => {
        setState((prevState) => ({
          ...prevState,
          isActivityIndicatorVisible: false,
        }));

        props.router.push("/provider/login");
      })
      .catch((e) => {
        const msg = e.message;
        setIsValidCredentials(false);

        setState((prevState) => ({
          ...prevState,
          isActivityIndicatorVisible: false,
        }));
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <PrimaryText size="title">{i18n.t("Provider")}</PrimaryText>
        <img className={classes.logo} src={Images.IMAGE_LOGO} />
        <MainInput
          disabled
          className={classes.input}
          value={state.email}
          placeholder={i18n.t("email")}
          onChange={(e) => handleChangeInput("email", e.target.value)}
        />
        <MainInput
          className={classes.input}
          value={state.firstName}
          placeholder={i18n.t("first_name")}
          onChange={(e) => handleChangeInput("firstName", e.target.value)}
        />
        <MainInput
          className={classes.input}
          value={state.lastName}
          placeholder={i18n.t("last_name")}
          onChange={(e) => handleChangeInput("lastName", e.target.value)}
        />
        <MainInput
          autoComplete="new-password"
          className={classes.input}
          value={state.password}
          placeholder={i18n.t("Password")}
          type="password"
          onChange={(e) => handleChangeInput("password", e.target.value)}
        />{" "}
        {isValidCredentials === false && (
          <div className={classes.errorDiv}> {i18n.t("duplicate_email")}</div>
        )}
         {isValidPassword === false && (
          <div className={classes.errorDiv}> {i18n.t("invalid_password_length")}</div>
         )}
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={state.acceptTerms}
              name="acceptTerms"
              onChange={(e) =>
                handleChangeInput("acceptTerms", e.target.checked)
              }
            />
          }
          label={
            <PrimaryText>{i18n.t("I accept the user agreement")}</PrimaryText>
          }
        />
        <PrimaryButton
          disabled={
            !state.firstName ||
            !state.lastName ||
            !state.password || isValidPassword === false || isValidCredentials === false ||
            !state.acceptTerms
          }
          size="large"
          width={200}
          className={classes.loginButton}
          onClick={handleClickRegister}
        >
          <WhiteText>{i18n.t("Register")}</WhiteText>
        </PrimaryButton>
        <Link to="/provider/login">
          <PrimaryText size="large">{i18n.t("Login")}</PrimaryText>
        </Link>
      </div>
    </div>
  );
};

export default Register;
