import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";

import DatePicker from "react-datepicker";

import { Colors, Images } from "../../styles";
import { PrimaryText } from "../Texts";

import i18n from "../../i18n";
import { Button } from "@material-ui/core";
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrowIcon: {
    color: Colors.COLOR_PRIMARY_TEXT,
  },
  calendarControl: {
    display: "flex",
    alignItems: "center",
  },
  calendarIcon: {
    margin: "0 0.5rem",
  },
  dateText: {
    marginTop: 3,
  },
  showDateButton:{
    color:Colors.COLOR_PRIMARY_BUTTON
  }
}));

const DateRangePicker = (props: Object) => {
  const classes = useStyles(props);
  const [startDate, setStartDate] = useState(new Date());
  const [isDateClicked, setDateClicked] = useState(false);
  const [state, setState] = useState();
  const { fromDate, toDate } = props;

  const onclickChooseDate = (date) => {
    setDateClicked(!isDateClicked);
  };

  const decrementDate = (date) => {
    setStartDate(date);
    setState((prevState) => ({
      ...prevState,
      fromDate: date,
    }));
    props.onClickPrevDate(null);
  };

  const dateClicked = (date) => {
    setStartDate(date);
    setState((prevState) => ({
      ...prevState,
      fromDate: date,
    }));
    props.onClickPrevDate(date);
  };

  return (
    <div className={classes.root}>
      <Box className={classes.calendarControl}>
        <IconButton onClick={decrementDate}>
          <ArrowBackIos className={classes.arrowIcon} />
        </IconButton>
        <img className={classes.calendarIcon} src={Images.ICON_CALENDAR_ALT} />
        <PrimaryText>{moment(fromDate).format("DD MMM")}</PrimaryText>
      </Box>
      <Button className={classes.showDateButton} onClick={onclickChooseDate}>{i18n.t("Choose date")}</Button>

      <Box className={classes.calendarControl}>
        <PrimaryText>{moment(toDate).format("DD MMM")}</PrimaryText>
        <img className={classes.calendarIcon} src={Images.ICON_CALENDAR_ALT} />
        <IconButton onClick={props.onClickNextDate}>
          <ArrowForwardIos className={classes.arrowIcon} />
        </IconButton>
      </Box>
      {isDateClicked ? (
        <div>
          {" "}
          <DatePicker
            selected={fromDate.toDate()}
            onChange={(date) => dateClicked(date)}
          ></DatePicker>{" "}
        </div>
      ) : null}
    </div>
  );
};

export default DateRangePicker;
