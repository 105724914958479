const Colors = {
  COLOR_BLACK: '#000000',
  COLOR_GRAY: '#777777',
  COLOR_GREEN_BUTTON: '#5CCD92',
  COLOR_GREEN_TEXT: '#4A7C8E',
  COLOR_INPUT_DEFAULT_BACKGROUND: 'rgba(112, 112, 112, 0.11)',
  COLOR_INPUT_DEFAULT_TEXT: '#5D4F96',
  COLOR_PRIMARY_LIGHT: '#A7A4CB',
  COLOR_PRIMARY_BUTTON: '#9079EF',
  COLOR_PRIMARY_TEXT: '#382E62',
  COLOR_RED: '#FF0000',
  COLOR_SECONDARY_TEXT: '#F788DD',
  COLOR_YELLOW: '#F7A782',
  COLOR_WHITE: '#FFFFFF',
};

export default Colors;
