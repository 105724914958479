export default {
  fields: [
    {
      name: 'met',
      type: 'input',
      title: 'Met',
    },
    {
      name: 'label',
      type: 'input',
      title: 'Label',
    },
    {
      name: 'type',
      type: 'input',
      title: 'Type',
    },
    {
      name: 'metMinute',
      type: 'input',
      title: 'Met Minute',
    }
  ],
} 