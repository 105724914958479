export default {
    fields: [
        {
            name: 'atc',
            type: 'input',
            title: 'Atc'
        },
        {
            name: 'labelTitle',
            type: 'input',
            title: 'Label Title'
        },
        {
            name: 'labelSubTitle',
            type: 'input',
            title: 'Label SubTitle'
        },
        {
            name: 'manufacturer',
            type: 'input',
            title: 'Manufacturer'
        },
        {
            name: 'dose',
            type: 'input',
            title: 'Dose'
        },
        {
            name: 'delivery',
            type: 'input',
            title: 'Delivery'
        },
        {
            name: 'activeSubstance',
            type: 'input',
            title: 'Active Substance'
        },
      ]
};