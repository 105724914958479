import {
  CreateActivityEntryMutation,
  CreateActivityMutation,
  CreateAnthropometryMutation,
  CreateBloodPressureMutation,
  CreateCalorieMutation,
  CreateCholesterolMutation,
  CreateDiabetesMutation,
  CreateDrugEntryMutation,
  CreateExerciseMutation,
  CreateFoodEntryMutation,
  CreateFoodMutation,
  CreateGlucoseDeviceMutation,
  CreateGlucoseManualMutation,
  CreateHealthMarkerMonitorIntervalMutation,
  CreateHealthMarkerMonitorMutation,
  CreateInsulinInjectionMutation,
  CreateKidneyMutation,
  CreateLifestyleMutation,
  CreateMedicineMutation,
  CreateNoteMutation,
  CreateRecipeMutation,
  CreateStepMutation,
  DeleteActivityEntryMutation,
  DeleteActivityMutation,
  DeleteAnthropometryMutation,
  DeleteBloodPressureMutation,
  DeleteCalorieMutation,
  DeleteCholesterolMutation,
  DeleteDiabetesMutation,
  DeleteDrugEntryMutation,
  DeleteExerciseMutation,
  DeleteFoodEntryMutation,
  DeleteGlucoseDeviceMutation,
  DeleteGlucoseManualMutation,
  DeleteHealthMarkerMonitorIntervalMutation,
  DeleteHealthMarkerMonitorMutation,
  DeleteInsulinInjectionMutation,
  DeleteKidneyMutation,
  DeleteLifestyleMutation,
  DeleteMedicineMutation,
  DeleteNoteMutation,
  DeleteRecipeMutation,
  DeleteStepMutation,
  UpdateActivityEntryMutation,
  UpdateActivityMutation,
  UpdateAnthropometryMutation,
  UpdateBloodPressureMutation,
  UpdateCalorieMutation,
  UpdateCholesterolMutation,
  UpdateDiabetesMutation,
  UpdateDrugEntryMutation,
  UpdateExerciseMutation,
  UpdateFoodEntryMutation,
  UpdateFoodMutation,
  UpdateFoodVariantMutation,
  UpdateGlucoseDeviceMutation,
  UpdateGlucoseManualMutation,
  UpdateHealthMarkerMonitorIntervalMutation,
  UpdateHealthMarkerMonitorMutation,
  UpdateInsulinInjectionMutation,
  UpdateKidneyMutation,
  UpdateLifestyleMutation,
  UpdateMedicineMutation,
  UpdateNoteMutation,
  UpdateRecipeMutation,
  UpdateSharingMutation,
  UpdateStepMutation,
  UpdateUserBaselineMutation,
  UpdateUserMutation,
} from '../mutations';

export const mutationList = {
  create: {
    'activityEntryTable': CreateActivityEntryMutation,
    'anthropometryTable': CreateAnthropometryMutation,
    'bloodPressureTable': CreateBloodPressureMutation,
    'calorieTable': CreateCalorieMutation,
    'cholesterolTable': CreateCholesterolMutation,
    'diabetesTable': CreateDiabetesMutation,
    'drugEntryTable': CreateDrugEntryMutation,
    'exerciseTable': CreateExerciseMutation,
    'foodEntryTable': CreateFoodEntryMutation,
    'glucoseDeviceTable': CreateGlucoseDeviceMutation,
    'glucoseManualTable': CreateGlucoseManualMutation,
    'insulinInjectionTable': CreateInsulinInjectionMutation,
    'kidneyTable': CreateKidneyMutation,
    'lifestyleTable': CreateLifestyleMutation,
    'noteTable': CreateNoteMutation,
    'stepTable': CreateStepMutation,
  },
  delete: {
    'activityEntryTable': DeleteActivityEntryMutation,
    'anthropometryTable': DeleteAnthropometryMutation,
    'bloodPressureTable': DeleteBloodPressureMutation,
    'calorieTable': DeleteCalorieMutation,
    'cholesterolTable': DeleteCholesterolMutation,
    'diabetesTable': DeleteDiabetesMutation,
    'drugEntryTable': DeleteDrugEntryMutation,
    'exerciseTable': DeleteExerciseMutation,
    'foodEntryTable': DeleteFoodEntryMutation,
    'glucoseDeviceTable': DeleteGlucoseDeviceMutation,
    'glucoseManualTable': DeleteGlucoseManualMutation,
    'insulinInjectionTable': DeleteInsulinInjectionMutation,
    'kidneyTable': DeleteKidneyMutation,
    'lifestyleTable': DeleteLifestyleMutation,
    'noteTable': DeleteNoteMutation,
    'stepTable': DeleteStepMutation,
  },
  update: {
    'activityEntryTable': UpdateActivityEntryMutation,
    'anthropometryTable': UpdateAnthropometryMutation,
    'bloodPressureTable': UpdateBloodPressureMutation,
    'calorieTable': UpdateCalorieMutation,
    'cholesterolTable': UpdateCholesterolMutation,
    'diabetesTable': UpdateDiabetesMutation,
    'drugEntryTable': UpdateDrugEntryMutation,
    'exerciseTable': UpdateExerciseMutation,
    'foodEntryTable': UpdateFoodEntryMutation,
    'glucoseDeviceTable': UpdateGlucoseDeviceMutation,
    'glucoseManualTable': UpdateGlucoseManualMutation,
    'insulinInjectionTable': UpdateInsulinInjectionMutation,
    'kidneyTable': UpdateKidneyMutation,
    'lifestyleTable': UpdateLifestyleMutation,
    'noteTable': UpdateNoteMutation,
    'stepTable': UpdateStepMutation,
    'userForm': UpdateUserMutation,
    'userBaselineForm': UpdateUserBaselineMutation,
  },
};