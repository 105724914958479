export default {
  fields: [
    {
      name: 'title',
      type: 'input',
      title: 'Title',
    },
    {
      name: 'description',
      type: 'input',
      title: 'Description',
    },
    {
      name: 'ingredients',
      type: 'input',
      title: 'Ingredients',
    },
    {
      name: 'taxonomyIngredients',
      type: 'input',
      title: 'Taxonomy Ingredients',
    },
    {
      name: 'instructions',
      type: 'input',
      title: 'Instructions',
    },
    {
      name: 'kcalPerServing',
      type: 'input',
      title: 'Kcal Per Serving',
    },
    {
      name: 'servings',
      type: 'input',
      title: 'Servings',
    },
    {
      name: 'prepTime',
      type: 'input',
      title: 'Prep Time',
    },
    {
      name: 'cookTime',
      type: 'input',
      title: 'Cook Time',
    },
    {
      name: 'totalTime',
      type: 'input',
      title: 'Total Time',
    },
    {
      name: 'totalFatG',
      type: 'input',
      title: 'Total Fat G',
    },
    {
      name: 'saturatedFatG',
      type: 'input',
      title: 'Saturated Fat G',
    },
    {
      name: 'cholesterolMg',
      type: 'input',
      title: 'Cholesterol Mg',
    },
    {
      name: 'sodiumMg',
      type: 'input',
      title: 'Sodium Mg',
    },
    {
      name: 'carbTotalG',
      type: 'input',
      title: 'Carb Total G',
    },
    {
      name: 'carbFiberG',
      type: 'input',
      title: 'Carb Fiber G',
    },
    {
      name: 'sugarsG',
      type: 'input',
      title: 'Sugars G',
    },
    {
      name: 'carbStarchG',
      type: 'input',
      title: 'Carb Starch G',
    },
    {
      name: 'proteinG',
      type: 'input',
      title: 'Protein G',
    },
    {
      name: 'taxonomyMealType',
      type: 'input',
      title: 'Taxonomy MealType',
    },
    {
      name: 'taxonomyDishType',
      type: 'input',
      title: 'Taxonomy Dish Type',
    },
    {
      name: 'taxonomyCookingStyle',
      type: 'input',
      title: 'Taxonomy Cooking Style',
    },
    {
      name: 'taxonomyCuisine',
      type: 'input',
      title: 'Taxonomy Cuisine',
    },
    {
      name: 'taxonomyDietHealth',
      type: 'input',
      title: 'Taxonomy DietHealth',
    },
    {
      name: 'taxonomyGluten',
      type: 'input',
      title: 'Taxonomy Guten',
    },
    {
      name: 'taxonomyNuts',
      type: 'input',
      title: 'Taxonomy Nuts',
    },
    {
      name: 'recipeCategoryId',
      type: 'select',
      data: 'recipeCategories',
      title: 'Recipe Category',
    },
  ]
};