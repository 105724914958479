export default [
  { id: 'date', label: 'Date' },
  { id: 'intensiveExercise', label: 'Intensive Exercise' },
  { id: 'lightExercise', label: 'Light Exercise' },
  { id: 'moderateExercise', label: 'Moderate Exercise' },
  { id: 'restingHeartRate', label: 'Resting Heart Rate' },
  { id: 'source', label: 'Source' },
  { id: 'standing', label: 'Standing' },
  { id: 'steps', label: 'Steps' },
];
