var Validator = require("jsonschema").Validator;
var v = new Validator();

export function validateFormData(formToValidate, validationSchema) {
  var validationResult = v.validate(formToValidate, validationSchema);

  var validationRes = {};
  if (validationResult.errors.length == 0) {
    return (validationRes = { isValid: true, validationObj: validationResult });
  } else {
    return (validationRes = {
      isValid: false,
      validationObj: validationResult,
    });
  }
}
