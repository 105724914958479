// there are two parts, administrator and provider
// only user in admin role should have access to admin pages
export const redirectToLoginIfNotAdmin = (redirectCallback) => {
  const accessToken = localStorage.getItem('access_token');
  if (!accessToken) {
    redirectCallback();
  } else {
    var options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        
      }),
    };
    fetch(process.env.REACT_APP_REST_API_URL + '/users/isadmin', options)
      .then(function(response) {
        return response.json();
      })
      .then(function(parsedData) {
        const isAdmin = parsedData["isAdmin"];
        if (!isAdmin) {
          redirectCallback();
        }
      })
  }
};