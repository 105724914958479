import { DropdownItems } from '../../constants/dropdowns';

export default {
  fields: [
    {
      name: 'date',
      type: 'input',
      title: 'DateTime',
    },
    {
      name: 'diabetesStatus',
      type: 'select',
      data: 'diabetes',
      title: 'Diabetes Status',
    }
  ],
  dependencies: [
    {
      name: 'diabetes',
      labelField: 'label',
      entries: DropdownItems.DiabetesDropdown,
    }
  ]
};