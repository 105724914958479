import {
  activityEntryTableConfig,
  anthropometryTableConfig,
  bloodPressureTableConfig,
  calorieTableConfig,
  cholesterolTableConfig,
  diabetesTableConfig,
  drugEntryTableConfig,
  exerciseTableConfig,
  foodEntryTableConfig,
  foodVariantTableConfig,
  glucoseDeviceTableConfig,
  glucoseManualTableConfig,
  insulinInjectionTableConfig,
  kindeyTableConfig,
  lifestyleTableConfig,
  noteTableConfig,
  stepTableConfig,
} from '../config/tables';

export const tableConfigList = {
  'activityEntry': activityEntryTableConfig,
  'anthropometry': anthropometryTableConfig,
  'bloodPressure': bloodPressureTableConfig,
  'calorie': calorieTableConfig,
  'cholesterol': cholesterolTableConfig,
  'diabetes': diabetesTableConfig,
  'drugEntry': drugEntryTableConfig,
  'exercise': exerciseTableConfig,
  'foodEntry': foodEntryTableConfig,
  'foodVariant': foodVariantTableConfig,
  'glucoseDevice': glucoseDeviceTableConfig,
  'glucoseManual': glucoseManualTableConfig,
  'insulinInjection': insulinInjectionTableConfig,
  'kidney': kindeyTableConfig,
  'lifestyle': lifestyleTableConfig,
  'note': noteTableConfig,
  'step': stepTableConfig,
};