import i18n from '../i18n';

export const userTableList = [
  {
    name: 'user',
    text: i18n.t('User'),
    link: 'user',
    type: 'form',
    query: 'userForm',
    form: 'userForm',
  },
  {
    name: 'userBaseline',
    text: i18n.t('UserBaseline'),
    link: 'user-baseline',
    type: 'form',
    query: 'userBaselineForm',
    form: 'userBaselineForm',
  },
  {
    name: 'drugEntry',
    text: i18n.t('DrugEntries'),
    link: 'drug-entry',
    type: 'table',
    query: 'drugEntryTable',
    table: 'drugEntryTable',
  },
  {
    name: 'activityEntry',
    text: i18n.t('ActivityEntries'),
    link: 'activity-entry',
    type: 'table',
    query: 'activityEntryTable',
    table: 'activityEntryTable',
  },
  {
    name: 'note',
    text: i18n.t('Notebook'),
    link: 'note',
    type: 'table',
    query: 'noteTable',
    table: 'noteTable',
  },
  {
    name: 'foodEntry',
    text: i18n.t('FoodEntry'),
    link: 'food-entry',
    type: 'table',
    query: 'foodEntryTable',
    table: 'foodEntryTable',
  },
  {
    name: 'diabetes',
    text: i18n.t('Diabetes'),
    link: 'diabetes',
    type: 'table',
    query: 'diabetesTable',
    table: 'diabetesTable',
  },
  {
    name: 'lifestyle',
    text: i18n.t('Lifestyle'),
    link: 'lifestyle',
    type: 'table',
    query: 'lifestyleTable',
    table: 'lifestyleTable',
  },
  {
    name: 'anthropometry',
    text: i18n.t('Anthropometry'),
    link: 'anthropometry',
    type: 'table',
    query: 'anthropometryTable',
    table: 'anthropometryTable',
  },
  {
    name: 'cholesterol',
    text: i18n.t('Cholesterol'),
    link: 'cholesterol',
    type: 'table',
    query: 'cholesterolTable',
    table: 'cholesterolTable',
  },
  {
    name: 'exercise',
    text: i18n.t('Exercise'),
    link: 'exercise',
    type: 'table',
    query: 'exerciseTable',
    table: 'exerciseTable',
  },
  {
    name: 'calorie',
    text: i18n.t('Calorie'),
    link: 'calorie',
    type: 'table',
    query: 'calorieTable',
    table: 'calorieTable',
  },
  {
    name: 'glucoseManual',
    text: i18n.t('GlucoseManual'),
    link: 'glucose-manual',
    type: 'table',
    query: 'glucoseManualTable',
    table: 'glucoseManualTable',
  },
  {
    name: 'glucoseDevice',
    text: i18n.t('GlucoseDevice'),
    link: 'glucose-device',
    type: 'table',
    query: 'glucoseDeviceTable',
    table: 'glucoseDeviceTable',
  },
  {
    name: 'insulinInjection',
    text: i18n.t('InsulinInjection'),
    link: 'insulin-injection',
    type: 'table',
    query: 'insulinInjectionTable',
    table: 'insulinInjectionTable',
  },
  {
    name: 'kidney',
    text: i18n.t('Kidney'),
    link: 'kidney',
    type: 'table',
    query: 'kidneyTable',
    table: 'kidneyTable',
  },
  {
    name: 'bloodPressure',
    text: i18n.t('BloodPressure'),
    link: 'blood-pressure',
    type: 'table',
    query: 'bloodPressureTable',
    table: 'bloodPressureTable',
  },
  // {
  //   name: 'exercise',
  //   text: 'Exercise',
  //   link: 'exercise',
  //   type: 'table',
  //   query: 'exerciseTable',
  //   table: 'exerciseTable',
  // },
  {
    name: 'step',
    text: i18n.t('Step'),
    link: 'step',
    type: 'table',
    query: 'stepTable',
    table: 'stepTable',
  },
];

export { queryList } from './queries';
export { formConfigList } from './forms';
export { tableConfigList } from './tables';
export { mutationList } from './mutations';
export { DropdownItems } from './dropdowns';
