export default {
  '1 unit is enough for 3,7g sugar': '1 enhet räcker till 3,7g socker',
  'About Dialiv': 'Om Dialiv',
  'Accept terms of use': 'Acceptera användarvillkor',
  'Activated markers': 'Aktiverade markörer',
  'Active shares': 'Aktiva delningar',
  'Activity level': 'Aktivitetsnivå',
  Add: 'Lägg till',
  "Add range":"Lägg till målintervall",
  Administrator:"Administratör",
  Age: 'Ålder',
  Amount: 'Mängd',
  auth_successful: "Lyckad auktorisering",
  auth_unsuccessful: "Misslyckad auktorisering",

  Average:"Medelvärde",
  and: 'och',
  another: 'annan',
  "Await provider registration":"Inväntar registrering",
  Description: 'Beskrivning',
  "Desired weight":"Önskad vikt",
  Title: 'Titel',
  'Another type': 'Annan typ',
  'Are you physically active': 'Är du fysiskt aktiv',
  Back: 'Tillbaka',
  'between you': 'mellan dig',
  'Blood sugar': 'Blodsocker',
  Breakfast: 'Frukost',
  Frukost:"Frukost",
  LunchSnack:"Lunch/Snack",
  Lunch:"Lunch",
  Burned: 'Förbränt',
  Förbränt:"Förbränt",
  "Bröst":"Bröst",
  Calculators: 'Kalkylatorer',
  Cancel: 'Avbryt',
  Carbohydrates: 'Kolhydrater',
  'Carbohydrate counting': 'Kolhydraträkning',
  Category: 'Kategori',
  Calories:"Kalorier",
  Change: 'Ändra',
  Chat: 'Chatta',
  Clear: 'Klart',
  Close: 'Stäng',
  Coffee: 'Fika',
  Combustion: 'Förbränning',
  Comment: 'Kommentar',
  'Completed shares': 'Avslutade delningar',
  confirm: 'Bekräfta',
  create_a_new:"Skapa ett nytt",
  confirm_date: 'Välj ett datum först',
  Connected: 'Kopplad',
  'Contains gluten': 'Innehåller gluten',
  'Contains nuts': 'Innehåller nötter',
  'Continuous glucose monitor (CGM)': 'Kontinuerlig glukosmätare (CGM)',
  Conversation: 'Konversation',
  'Convert blood sugar': 'Omvandla blodsocker',
  Date: 'Datum',
  'Date of birth': 'Födelsedatum',
  day: 'dag',
  'Days of combustion': 'Dagar förbränning',
  Delete: 'Radera',
  Diary: 'Dagbok',
  Dinner: 'Middag',
  Distribution: 'Fördelning',
  Done: 'Klar',
  Done: 'Klar',
  Doses: 'Doser',
  'Do you eat any special diet': 'Äter du någon särskild diet',
  'Do you have diabetes': 'Har du diabetes',
  'Do you smoke': 'Röker du',
  'Do you use any of the following': 'Använder du någon av nedanstående',
  'Do you want to improve your blood sugar':
    'Vill du förbättra ditt blodsocker',
  'Do you want to improve your risk factors':
    'Vill du förbättra dina riskfaktorer',
  'Do you want to lose weight': 'Vill du gå ner i vikt',
  Drink: 'Dryck',
  duplicate_email: 'E-post är redan registrerad',
  duplicate_sharing: "Du delar redan din data med denna person",
  'E-mail': 'E-post',
  eat: 'ät',
  Eaten: 'Ätit',
  Energy: 'Energi',
  'Enter amount': 'Ange mängd',
  'Enter target range': 'Ange målintervall',
  Example: 'Exempel: ',
  'Example Apple Watch, Fitbit': 'Exempel: Apple Watch, Fitbit',
  'Example Glucomen': 'Exempel: Glucomen',
  'Example Libre': 'Exampel: Freestyle Libre, Dexcom',
  Exercise: 'Träning',
  'Exercises easily 1 to 3 days / week': 'Tränar lätt 1 till 3 dagar / vecka',
  'Exercise daily, or intensely 3 to 4 days / week':
    'Tränar dagligen, eller intensivt 3 till 4 dagar / vecka',
  'Exercises intensively 6 to 7 days / week':
    'Tränar intensivt 6 till 7 dagar / vecka',
  'Exercises moderately 4 to 5 days / week':
    'Tränar måttligt 4 till 5 dagar / vecka',
  'Exercise very intensively daily or have a physically demanding job':
    'Tränar väldigt intensivt dagligen eller har ett fysiskt krävande jobb',
  'ex Glucomen': 'ex Glucomen',
  Fat: 'Fett',
  first_name: 'Förnamn',
  Food: 'Mat',
  food:"mat",
  "activitie":"Aktivitet",
  'Food & Calories': 'Mat & kalorier',
  for: 'i',
  'Forgot password?': 'Glömt lösenord?',
  'Gestational diabetes': 'Graviditetsdiabetes',
  gestational: 'Graviditetsdiabetes',
  "Genomsnittligt glukos": "Genomsnittligt glukos",
  glucose: 'glukos',
  'Glucose Strips': 'Blodglukosmätare',
  "Glucose strips":"Blodglukosmätare",
  'Health Markers': 'Hälsomarkörer',
  "Mått runt bröstkorg":"Mått runt bröstkorg",
  'Here you can comment and keep a logbook':
    'Här kan du kommentera och föra loggbok',
  'Here your values are pre-filled. You can change the values if you want.':
    'Här är dina värden förifyllda. Du kan ändra värdena om du vill.',
  Height: 'Längd',
  hours: 'timmar',
  'How much do you weigh': 'Hur mycket väger du',
  'How much insulin do you take each day':
    'Hur mycket insulin tar du varje dag',
  'How much should I eat per day': 'Hur mycket ska jag äta per dag',
  'How tall are you': 'Hur lång är du',
  'I do not exercise, or very little': 'Jag tränar inte, eller mycket lite',
  'I eat a regular diet': 'jag äter vanlig kost',
  'If you click on "About us" or "Terms, policy" you will come to a static page with information':
    'Om man klickar på "Om oss" Eller "Villkor, policy ..." så kommer Man till en statisk sida med information',
  'If you do not want to lose weight, you can enter your current weight to calculate the number of calories you need to maintain your weight. If you want to lose weight, aim to 0.5 to 1 kg/week.':
    'Om du inte vill gå ner i vikt kan du ange din nuvarande vikt för att beräkna antal kalorier du behöver för att behålla din vikt. Om du vill gå ner i vikt bör du sikta på att gå ner 0.5 till 1 kg/vecka.',
  Ingredients: 'Ingredienser',
  Installing: 'Inställningar',
  Instructions: 'Instruktioner',
  'Insulin Pump': 'Insulinpump',
  Intensive: 'Intensiv',
  'Intermittent fasting': 'Intermittent fasta',
  invalid_email_or_password: 'Felaktiga inloggningsuppgifter',
  invalid_credentials: 'Felaktiga inloggningsuppgifter',
  Iron: 'Järn',
  left: 'kvar',
  last_name: 'Efternamn',
  Length: 'Längd',
  'Log in': 'Logga in',
  'Low carbohydrate diet': 'Lågkolhydratkost',
  Lunch: 'Lunch',
  malformed_email: 'Felaktigt e-postformat',
  Man: 'Man',
  Medicine: 'Läkemedel',
  Menu: 'Meny',
  Minerals: 'Mineraler',
  minutes: 'minuter',
  'Mobile number': 'Mobilnummer',
  Moderate: 'Måttlig',
  'My medicines': 'Mina läkemedel',
  'My profile': 'Min profil',
  Name: 'Namn',
  'New dose': 'Ny dos',
  'New message': 'Nytt meddelande',
  'New note': '+ Lägg till',
  'New password': 'Nytt lösenord',
  Next: 'Nästa',
  'Not connected': 'Ej kopplad',
  not_accept_terms: 'Vänligen acceptera användarvillkoren',

  'Number of steps': 'Antal steg',
  'Nutritional Information': 'Näringsinformation',
  'Paired devices': 'Kopplade enheter',
  Code: 'Ange kod',
  Password: 'Lösenord',
  invalid_phone_nr: 'Felaktigt telefonnummer',
"Omvandla":"Omvandla",
  'The password needs to be atleast 8 characters long':
    'Lösenordet måste vara minst 8 tecken långt',
  'Physical activity': 'Fysisk aktivitet',
  grant_permissions: "Vänligen ge tillåtelse för delning av data i appinställningar",
  portions: 'portioner',
  "Overview":"Översikt",
  "My account": "Mitt konto",
  "Logout":"Logga ut",
  "People":"Personer",
  diabetes_type:"Typ av diabetes",
  "Sharings":"Delningar",
  "From":"Från",
  "Finished":"Avslutad",
  "to":"till",
  "User":"Användare",
  "Recipe":"Recept",
  "recipe":"recept",
  'Preferred diet': 'Föredragen kost',
  Protein: 'Protein',
  provide_code: 'Ange verifieringskod',
  invalid_first_name: 'Ogiltigt format för förnamn',
  invalid_last_name: 'Ogiltigt format för efternamn',
  invalid_password_length: 'Lösenordet måste innehålla minst 8 tecken',
  invalid_verification_code: 'Felaktig verifieringskod',
  Recept: 'Recept',
  recipes: 'Recept',
  Register: 'Registrera dig',
  "Registered values":"Registrerade värden",
  Registrations: 'Registreringar',
  NoData: 'Ingen data',
  'Reminders at each dose': 'Påminnelser vid varje dos',
  Remove: 'Avsluta',
  'Repeat password': 'Upprepa lösenord',
  'Reset password': 'Återställ lösenord',
  Results: 'Resultat',
  rule: 'regeln',
  Save: 'Spara',
  'Send one-time code': 'Skicka en engångskod',
  'Select calculator': 'Välj kalkylator',
  'Select gender': 'Välj kön',
  'Select health marker': 'Välj hälsomarkörer',
  "Select table":"Välj tabell",
  Send: 'Skicka',
  Sex: 'Kön',
  Shares: 'Fördelning',
  'Share in interval': 'Andel i intervall',
  Snack: 'Mellanmål',
  Specify: 'Ange',
  Standing: 'Stående',
  'Static Info': 'Statisk info',
  code_verification_success: 'Koden verifierad, logga in för att fortsätta',
  'Target weight': 'Målvikt',
  'Taken when necessary': 'Tas vid behov',
  'Tell me a little about you': 'Berätta lite om dig',
  'Terms, Policy & Information Security':
    'Villkor, Policy & Informationssäkerhet',
  "Today's activities": 'Dagens aktiviteter',
  "Today's intake": 'Dagens intag',
  'To maintain your current weight': 'För att behålla din nuvarande vikt',
  'To reach the goals': 'För att nå målen',
  Totally: 'Totalt',
  type: 'typ',
  'Type 1 diabetes': 'Typ 1 diabetes',
  'Type 2 diabetes': 'Typ 2 diabetes',
  "Type of meal":"Typ av måltid",
  units: 'enheter',
  value: 'värde',
  Vegetarian: 'Vegetarisk',
  verification_code_info_sent:
    'Vi har skickat en verifieringskod till din e-postadress. Vänligen skriv in koden nedan.',
  verify_code: 'Verifiera',
  'View favorites': 'Visa favoriter',
  Walk: 'Lätt',
  Favorite: "Favorit",
  tap_water: 'Vatten kranvatten',
  Water: 'Vatten',
  week: 'vecka',
  weeks: 'veckor',
  Weeks: 'Veckor',
  Favorite: 'Favorit',
  'Weight planning': 'Viktplanering',
  'Weight & metabolism': 'Vikt & metabolism',
  'What do you want to add': 'Vad vill du lägga till',
  'What kind of diabetes do you have': 'Vilken typ av diabetes har du',
  'which is': 'vilket är',
  'What is your target weight': 'Vad är din målvikt',
  'What type of diabetes do you have': 'Vilken typ av diabetes har du',
  'When were you born': 'När är du född',
  'When did the treatment begin?': 'När började behandlingen',
  'When do you want to reach your target weight': 'När vill du nå din målvikt',
  'Which do you use': 'Vilken använder du',
  'Which insulin pump do you use': 'Vilken insulinpump använder du',
  Woman: 'Kvinna',
  'Write here': 'Skriv här',
  Years: 'år',
  Yes: 'Ja',
  You: 'Du',
  'You need to reduce your calorie intake by ':
    'Du behöver minska ditt intag av kalorier med ',
  'You reach the goal if you eat': 'Du når målet om du äter',
  'Your current weight': 'Din nuvarande vikt',
  'Your password is now changed!': 'Ditt lösenord är nu bytt!',
  logout: 'Logga ut',
  'Change Password': 'Ändra lösenord',
  'Share data': 'Dela Data',
  "Share data and chat": "Dela data och chatta",
  'New Sharing': 'Ny delning',
  "Sharing start":"Delningsstart",
  "Sharing status":"Delningsstatus",
  "Your comment":"Din kommentar",
  "General diabetes control":"Allmän diabeteskontroll",
  "Perosn":"Person",
  "Messages":"Meddelanden",
  no_unread_msgs:"Inga olästa meddelanden",
  health_care_provider_relative:"Vårdgivare/anhörig",
  Username:"Användarnamn",
  email: 'E-post',
  phone: 'Telefonnummer',
  login:"Logga in",
  "Forgot password":"Glömt lösenordet",
  addSharingDescription:
    'Här kan du lägga till personer som kan se din data. Personen du delar data med får en inbjudan via e-post. Du kan ta bort en delning när som helst.',
  shareDataDashboardDescription:
    'Du kan dela dina personliga data. Du är ansvarig för beslutet att dela din data och för att rätt person har åtkomst till din data.',
    share_with_dialiv_free:"för att bjuda in en av våra läkare och börja chatta. Det är kostnadsfritt.",
  iHaveACode: 'Jag har en kod',
  'Glucose, random value': 'Glukos, slumpmässigt värde',
  'Glucose, fast': 'Glukos, fasta',
  'Glucose, before breakfast': 'Glukos, innan frukost',
  'Glucose, after breakfast': 'Glukos, efter frukost',
  'Glucose, before lunch': 'Glukos, innan lunch',
  'Glucose, after lunch': 'Glukos, efter lunch',
  'Glucose, before dinner': 'Glukos, innan middag',
  'Glucose, after dinner': 'Glukos, efter middag',
  HbA1c: 'HbA1c',
  'LDL colesterol': 'LDL kolesterol',
  'Total colesterol': 'Total kolesterol',
  'HDL colesterol': 'HDL kolesterol',
  Triglycerides: 'Triglycerider',
  Weight: 'Vikt',
  Waistmeasurement: 'Midjemått',
  BMI: 'BMI',
  'Measurements around arms and chest': 'Mått runt armar och bröstkorg',
  'Burning (kcal), in total': 'Förbränning (kcal), totalt',
  'Eaten (kcal), in total': 'Ätit (kcal),totalt',
  Carbohydrates: 'Kolhydrater',
  "drug":"läkemedel",
  Chest:"Bröstkorg",
  Protein: 'Protein',
  Fat: 'Fett',
  'Water consumption': 'Vattenkonsumption',
  'U-albumin/U-kreatinin': 'U-albumin/U-kreatinin',
  Kreatinin: 'Kreatinin',
  eGFR: 'eGFR',
  'Systolic bloodpressure': 'Systoliskt blodtryck',
  'Diastolic bloodpressure': 'Diastoliskt blodtryck',
  Insulin: 'Insulin',
  'Long-acting Insulin': 'Långverkande insulin',
  'Insulin fast-acting': 'Snabbverkande insulin',
  Steps: 'Steg',
  Training: 'Träning',
  Heartfrequency: 'Hjärtfrekvens',
  'Stress from smartwatch': 'Stress från smartwatch',
  'Sleep from smartwatch': 'Sömn från smartwatch',
  "Smartwatch":"Smartwatch",

  GLUCOSE: 'GLUKOS',
  NUTRITION: 'NÄRING',

  LIFESTYLE: 'LIVSSTIL',

  MEDICINE: 'LÄKEMEDEL',
  ANTHROPOMETRY: 'ANTROPOMETRI',

  'HEART, VESSELS & KIDNEYS': 'HJÄRTA, KÄRL & NJURAR',
  NoData: 'Ingen data',
  Done: 'Klar',
  Klar: 'Klar',
  Systolic: 'Systolisk',
  Diastolic: 'Diastoliskt',

  'Glukos, slumpmässigtvärde': 'Glukos, slumpmässigt värde',
  'Glukos, fasta': 'Glukos, fasta',
  'Glukos, innan frukost': 'Glukos, innan frukost',
  'Glukos, efter frukost': 'Glukos, efter frukost',
  'Glukos, innan lunch': 'Glukos, innan lunch',
  'Glukos, efter lunch': 'Glukos, efter lunch',
  'Glukos, innan middag': 'Glukos, innan middag',
  'Glukos, efter middag': 'Glukos, efter middag',
  HbA1c: 'HbA1c',
  'LDL kolesterol': 'LDL kolesterol',
  'Total kolesterol': 'Total kolesterol',
  'HDL kolesterol': 'HDL kolesterol',
  Triglycerider: 'Triglycerider',
  Vikt: 'Vikt',
  Midjemått: 'Midjemått',
  BMI: 'BMI',
  'Mått runt armar och bröstkorg': 'Mått runt armar och bröstkorg',
  "Mått runt armar":"Mått runt armar",
  'Förbränning (kcal), totalt': 'Förbränning (kcal), totalt',
  'Ätit (kcal),totalt': 'Ätit (kcal),totalt',
  Kolhydrater: 'Kolhydrater',

"Pre-diabetes": "Fördiabetes",
"Dont have diabetes":"Har inte diabetes",
"Obesity/Overweight": "Fetma/Övervikt",
  Protein: 'Protein',
  Fett: 'Fett',
  'Vattenkonsumption': 'Vatten konsumption',
  'U-albumin/U-kreatinin': 'U-albumin/U-kreatinin',
  Kreatinin: 'Kreatinin',
  omvandla_glukos:"Omvandla glukos",
  Glukos:"Glukos",
  Ätit:"Ätit",
  eGFR: 'eGFR',
  'Systoliskt blodtryck': 'Systoliskt blodtryck',
  'Diastoliskt blodtryck': 'Diastoliskt blodtryck',
  Insulin: 'Insulin',
  'Långvarigt Insulin': 'Långvarigt Insulin',
  'Insulin snabbagerande': 'Insulin snabbagerande',
  Steg: 'Steg',
  Träning: 'Träning',
  Hjärtfrekvens: 'Hjärtfrekvens',
  'Stress från smartwatch': 'Stress från smartwatch',
  'Sömn från smartwatch': 'Sömn från smartwatch',

  GLUKOS: 'GLUKOS',
  NÄRING: 'NÄRING',

  LIVSSTIL: 'LIVSSTIL',

  LÄKEMEDEL: 'LÄKEMEDEL',
  ANTROPOMETRI: 'ANTROPOMETRI',

  'HJÄRTA, KÄRL & NJURAR': 'HJÄRTA, KÄRL & NJURAR',
  'Ingen data': 'Ingen data',
  Klar: 'Klar',
  Systolisk: 'Systolisk',
  Diastoliskt: 'Diastoliskt',
  'Ange antalet enheter som ska beräknas':
    'Ange antalet enheter som ska beräknas',

    Label:"Etikett",
Unit: "Mått",
Kcal: "Kcal",
Carbs:"Kolhydrater",
Fibre: "Fiber",
Ash: "Aska",
Sugars: "Socker",
"Sum Saturated": "Mättad summa",
"Sum Monounsaturated":"Enkelomättad summa",
"Sum Polyunsaturated":"Fleromättad summa" ,
Cholesterol: "Kolesterol",
"Choose date":"Välj datum",
 Retinol: "Retinol",
 VitaminA: "Vitamin A",
 BKaroten: "Betakaroten",
 VitaminD: "Vitamin D",
 VitaminE: "Vitamin E",
VitaminK: "Vitamin K",
Tiamin: "Tiamin",
 "Tibo flavin": "Riboflavin",
VitaminC: "Vitamin C",
Niacin: "Niacin",
VitaminB6: "Vitamin B6",
 VitaminB12: "Vitamin B12",
 "Folic Acid": "Folsyra",
 Phosphorus: "Fosfor",
 Calcium: "Kalcium",
 Potassium: "Kalium",
 Copper: "Koppar",
 Magnesium: "Magnesium",
 Sodium: "Sodium",
 Selenium: "Selen",
Zink: "Zink",
 Source: "Källa",
 Met:"Metabolisk ekvivalent",
 "Type": "Typ",
 "Met Minute": "Metabolisk ekvivalent per minut",
 Atc: "ATC-kod",
 "Label Title": "Etikett",
  "Label SubTitle": "Undertext",
  Manufacturer: "Tillverkare",
  Dose: "Dos",
  Delivery: "Läkemedelsleverans",
  "Active Substance": "Aktiv substans",
  
UserBaseline:"Allmän information",
DrugEntries:"Mediciner",
ActivityEntries:"Aktiviteter",
Notebook: "Journal",
FoodEntry:"Kost",
Diabetes:"Diabetes",
Lifestyle:"Livsstil",
Anthropometry:"Antropometri",
Cholesterol:"Kolesterol",
Exercise:"Träning",
Calorie:"Kalori",
GlucoseManual: "Glukosmanual",
GlucoseDevice: "Glukosmätare",
InsulinInjection:"Insulin injektion",
Kidney:"Njure",
BloodPressure:"Blodtryck",
Step:"Steg",
"First Name":"Förnamn",
 "Last Name":"Efternamn",
 "Email":"E-post",
"Birth Date":"Födelsedatum",
"Cell Phone":"Mobilnummer",
"Allmän information": "Allmän information",
"Weight Loss": "Önskar viktförlust",
"Glucose Control": "Glukoskontroll",
"Risk Control": "Riskkontroll",
"Target Weight": "Målvikt",
"Target Weeks": "Antal veckor att nå viktmålet",
"Use Pump": "Använder pump",
"Pump Type" : "Typ av pump",
"Use Cgm": "Använder CGM",
"Cgm Type" :"Typ av CGM",
"Use Strips": "Använder glukosstickor",
"Use SmartWatch": "Använder SmartWatch",
"SmartWatch Type": "Typ av SmartWatch",

'highBloodPressure': 'Högt blodtryck',
'bloodFatDisorder': 'Blodfettsrubbningar',
'exerciseHabits': 'Motionsvanor',
'dietaryHabits': 'Kostvanor',
"obesityOverweight":"Fetma/övervikt",

"Pre-diabetes": "Pew-diabetes",
"Dont have diabetes":"Har inte diabetes",
"Obesity/Overweight": "Fetma/Övervikt",


dietDifficulties:"Svårt att ändra kosten",
insufficientExercise:"Otillräckligt med motion",
stress:"Stress",
lackOfSupport: "Saknar stöd",
lackOfKnowledge:"Otillräckligt med kunskap för att hantera diabetes",
noneMentioned:"Inget av ovanstående",
complications:"Komplikationer",

worry_level:"Orohetsnivå",

diabetes_duration:"Diabetes längd",
diabetes_treatment:"Diabetes behandling",
required:"är ett krav",
active:"Aktiv",


};
