export default {
  fields: [
    {
      name: 'date',
      type: 'input',
      title: 'DateTime',
    },
    {
      name: 'amountMinutes',
      type: 'input',
      title: 'Amount Minutes',
    },
    {
      name: 'activityId',
      type: 'select',
      data: 'activities',
      title: 'Activity',
    }
  ],
  dependencies: [ 
    {
      name: 'activities',
      labelField: 'label',
    },
    {
      name: 'anthropometries',
    }
  ]
};