export { default as activityEntryTableConfig } from './activityEntry';
export { default as anthropometryTableConfig } from './anthropometry';
export { default as calorieTableConfig } from './calorie';
export { default as cholesterolTableConfig } from './cholesterol';
export { default as diabetesTableConfig } from './diabetes';
export { default as drugEntryTableConfig } from './drugEntry';
export { default as exerciseTableConfig } from './exercise';
export { default as foodEntryTableConfig } from './foodEntry';
export { default as foodVariantTableConfig } from './foodVariant';
export { default as glucoseDeviceTableConfig } from './glucoseDevice';
export { default as glucoseManualTableConfig } from './glucoseManual';
export { default as insulinInjectionTableConfig } from './insulinInjection';
export { default as lifestyleTableConfig } from './lifestyle';
export { default as noteTableConfig } from './note';
export { default as bloodPressureTableConfig } from './bloodPressure';
export { default as kindeyTableConfig } from './kidney';
export { default as stepTableConfig } from './step';