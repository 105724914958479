import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import { OutlineButton } from "../../Buttons";
import { PrimaryText, YellowText } from "../../Texts";
import SkeletonContainer from "../../SkeletonContainer";

import i18n from "../../../i18n";
import { Images } from "../../../styles";
import { emptifyObject } from "../../../utils";
import { validateFormData } from "../../../utils/validation/FormValidator";
import activitySchema from "../../../utils/validation/ValidationSchemas/activitySchema.json";
import foodSchema from "../../../utils/validation/ValidationSchemas/foodSchema.json";
import medicineSchema from "../../../utils/validation/ValidationSchemas/medicineSchema.json";
import recipeSchema from "../../../utils/validation/ValidationSchemas/recipeSchema.json";

import ImageUploader from "./ImageUploader";
import { getByPlaceholderText } from "@testing-library/react";

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    padding: "1.5rem 2rem",
    marginTop: "1rem",
  },
  formItem: {
    width: 350,
  },
  formItemLabel: {
    width: 250,
  },
  imageText: {
    margin: "0 3rem 0 2rem",
  },
  outlineButton: {
    padding: 0,
    marginLeft: "1rem",
  },
  outlineButtonText: {
    marginLeft: "0.5rem",
  },
}));

const GeneralForm = (props) => {
  const classes = useStyles();
  const [isFormInputValid, setIsFormInputValid] = useState();
  const [invalidInputs, setInvalidInputs] = useState([]);
  const [state, setState] = useState({
    formData: {},
  });
  const [_, __, path] = window.location.pathname.split("/");
  const {
    config,
    name,
    objectId,
    dependencies,
    createMutation,
    deleteMutation,
    updateMutation,
    nameField,
  } = props;
  console.log(config);
  let schema;

  if (name === "recipe") {
    schema = recipeSchema;
  } else if (name === "food") {
    schema = foodSchema;
  } else if (name === "activity") {
    schema = activitySchema;
  } else if (name === "medicine") {
    schema = medicineSchema;
  }

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      formData: props.formData,
    }));
  }, [props.formData]);

  const getTypeOfInput = (input) => {
    for (var key in schema.properties) {
      if (input == key) {
        return schema.properties[key].type;
      }
    }
  };

  const placeHolderText = (title, name) => {
    var typeOfInput = getTypeOfInput(name) == "string"  ? "text" : "number";
    if (name == "ingredients") {
      return title + " | Required type: " + typeOfInput + " colon separated";
    } else {
      return title + " | Required type: " + typeOfInput;
    }
  };
  const handleChangeInput = (key, value) => {
    let { formData } = state;

    if (formData) {
      formData[key] = value;
    } else {
      formData = { [key]: value };
    }

    setState((prevState) => ({
      ...prevState,
      formData,
    }));
  };

  const handleClickSave = () => {
    var { formData } = state;
    if (formData === undefined) {
      formData = {};
    }

    var validationResult = validateFormData(formData, schema);
    setIsFormInputValid(validationResult.isValid);
    if (!validationResult.isValid) {
      var invalidInputs = [];
      validationResult.validationObj.errors.forEach((error) => {
        invalidInputs.push(error.argument);
      });
      setInvalidInputs(invalidInputs);
      return;
    } else if (name === "activityEntry") {
      const activity = dependencies.activities.find(
        (activity) => activity.value === formData.activityId
      );
      const weight = dependencies.anthropometries
        .filter((anthropometry) => anthropometry.weight)
        .sort((a, b) => a.date < b.date)[0].weight;

      formData.energy = parseFloat(
        formData.amountMinutes * activity.metMinute * weight
      ).toFixed(2);
    }
    if (objectId) {
      delete formData._id;
      updateMutation(parseInt(objectId), emptifyObject(formData)).then(() =>
        props.onUpdate()
      );
    } else {
      createMutation(formData).then((res) => {
        props.createMutation(formData);
      });
    }
  };

  const handleClickDelete = () => {
    deleteMutation({ id: parseInt(objectId) }).then(() => {
      // props.router.push(`/administrator/${path}/new`);
      props.onDelete();
    });
  };

  const handleUploadFile = (body) => {
    handleChangeInput("imgUrl", body.fileName);
    handleChangeInput("imgDispName", body.originalName);
  };

  const renderErrorText = (item) => {
    for (let index = 0; index < invalidInputs.length; index++) {
      const element = invalidInputs[index];
      if (element == item.name) {
        return (
          <p style={{ color: "red", fontSize: "8px" }}>
            {item.title} {i18n.t("required")}
          </p>
        );
      }
    }
  };
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Box display="flex" justifyContent="space-between">
          {nameField ? (
            <SkeletonContainer
              isLoading={objectId && !state.formData}
              width={350}
              height={32}
            >
              <TextField
                placeholder={i18n.t("Title")}
                className={classes.formItem}
                value={state.formData && (state.formData[nameField] || "")}
                onChange={(e) => handleChangeInput(nameField, e.target.value)}
              />
            </SkeletonContainer>
          ) : (
            <div />
          )}
          <div>
            <OutlineButton
              size="small"
              width={120}
              className={classes.outlineButton}
              onClick={handleClickSave}
            >
              <img src={Images.ICON_SAVE}></img>
              <PrimaryText className={classes.outlineButtonText}>
                {i18n.t("Save")}
              </PrimaryText>
            </OutlineButton>
            {objectId && (
              <OutlineButton
                size="small"
                width={120}
                className={classes.outlineButton}
                onClick={handleClickDelete}
              >
                <img src={Images.ICON_TIMES}></img>
                <PrimaryText className={classes.outlineButtonText}>
                  {i18n.t("Remove")}
                </PrimaryText>
              </OutlineButton>
            )}
          </div>
        </Box>
      </Card>
      {path === "recipes" && (
        <ImageUploader
          formData={state.formData}
          onUploadFile={handleUploadFile}
        />
      )}
      <Card className={classes.card}>
        {config.fields
          .filter((item) => item.name !== nameField)
          .map((item) => (
            <Box alignItems="center" height={60}>
              {item.type === "input" && (
                <SkeletonContainer
                  isLoading={objectId && !state.formData}
                  width={350}
                  height={32}
                >
                  <TextField
                    placeholder={placeHolderText(item.title, item.name)}
                    className={classes.formItem}
                    value={state.formData && (state.formData[item.name] || "")}
                    onChange={(e) =>
                      handleChangeInput(item.name, e.target.value)
                    }
                  />
                  {renderErrorText(item)}
                </SkeletonContainer>
              )}
              {item.type === "select" && (
                <SkeletonContainer
                  isLoading={objectId && !state.formData}
                  width={350}
                  height={32}
                >
                  {i18n.t("Type of meal")}
                  <br></br>
                  <Select
                    className={classes.formItem}
                    value={state.formData && state.formData[item.name]}
                    onChange={(e) =>
                      handleChangeInput(item.name, e.target.value)
                    }
                  >
                    {(dependencies[item.data] || []).map((item) => (
                      <MenuItem value={item.value}>
                        {i18n.t(item.label)}
                      </MenuItem>
                    ))}
                  </Select>
                </SkeletonContainer>
              )}
              {item.type === "checkbox" && (
                <SkeletonContainer
                  isLoading={objectId && !state.formData}
                  width={350}
                  height={32}
                >
                  <Checkbox
                    checked={
                      (state.formData && state.formData[item.name]) || false
                    }
                    onChange={(e) =>
                      handleChangeInput(item.name, e.target.checked)
                    }
                  ></Checkbox>
                  {i18n.t(item.name)}
                </SkeletonContainer>
              )}
            </Box>
          ))}
      </Card>
    </div>
  );
};

export default GeneralForm;
