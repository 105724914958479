export default {
  fields: [
    {
      name: 'sex',
      type: 'input',
      title: 'Sex',
    },
    // {
    //   name: 'height',
    //   type: 'input',
    //   title: 'Height',
    // },
    {
      name: 'aimWeightLoss',
      type: 'checkbox',
      title: 'Weight Loss',
    },
    {
      name: 'aimGlucoseControl',
      type: 'checkbox',
      title: 'Glucose Control',
    },
    {
      name: 'aimRiskControl',
      type: 'checkbox',
      title: 'Risk Control',
    },
    {
      name: 'aimGoalWeight',
      type: 'input',
      title: 'Target Weight',
    },
    {
      name: 'aimWeeksWeight',
      type: 'input',
      title: 'Target Weeks',
    },
    {
      name: 'usePump',
      type: 'checkbox',
      title: 'Use Pump',
    },
    {
      name: 'usePumpType',
      type: 'input',
      title: 'Pump Type',
    },
    {
      name: 'useCgm',
      type: 'checkbox',
      title: 'Use Cgm',
    },
    {
      name: 'useCgmType',
      type: 'input',
      title: 'Cgm Type',
    },
    {
      name: 'useStrips',
      type: 'checkbox',
      title: 'Use Strips',
    },
    {
      name: 'useSmartWatch',
      type: 'checkbox',
      title: 'Use SmartWatch',
    },
    {
      name: 'useSmartWatchType',
      type: 'input',
      title: 'SmartWatch Type',
    },
  ]
};