export default {
  fields: [
    {
      name: 'date',
      type: 'input',
      title: 'DateTime',
    },
    {
      name: 'glucoseABreakfast',
      type: 'input',
      title: 'After Breakfast',
    },
    {
      name: 'glucoseADinner',
      type: 'input',
      title: 'After Dinner',
    },
    {
      name: 'glucoseALunch',
      type: 'input',
      title: 'After Lunch',
    },
    {
      name: 'glucoseBBreakfast',
      type: 'input',
      title: 'Before Breakfast',
    },
    {
      name: 'glucoseBDinner',
      type: 'input',
      title: 'Before Dinner',
    },
    {
      name: 'glucoseBLunch',
      type: 'input',
      title: 'Before Lunch',
    },
    {
      name: 'glucoseBFasting',
      type: 'input',
      title: 'Before Fasting',
    },
    {
      name: 'glucoseRandom',
      type: 'input',
      title: 'Random',
    },
    {
      name: 'hba1c',
      type: 'input',
      title: 'HBA1C',
    },
  ]
};
