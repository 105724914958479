export default [
  { id: 'date', label: 'Date' },
  { id: 'glucoseABreakfast', label: 'After Breakfast' },
  { id: 'glucoseADinner', label: 'After Dinner' },
  { id: 'glucoseALunch', label: 'After Lunch' },
  { id: 'glucoseBBreakfast', label: 'Before Breakfast' },
  { id: 'glucoseBDinner', label: 'Before Dinner' },
  { id: 'glucoseBLunch', label: 'Before Lunch' },
  { id: 'glucoseBFasting', label: 'Before Fasting' },
  { id: 'glucoseRandom', label: 'Random' },
  { id: 'hba1c', label: 'HBA1C' },
];
