export default {
  fields: [
    {
      name: 'firstName',
      type: 'input',
      title: 'First Name',
    },
    {
      name: 'lastName',
      type: 'input',
      title: 'Last Name',
    },
    {
      name: 'email',
      type: 'input',
      title: 'Email',
    },
    {
      name: 'birthDate',
      type: 'input',
      title: 'Birth Date',
    },
    {
      name: 'cellPhone',
      type: 'input',
      title: 'Cell Phone',
    },
  ]
};