export default {
    fields: [
        {
            name: 'label',
            type: 'input',
            title: 'Label'
        },
        {
            name: 'unit',
            type: 'input',
            title: 'Unit'
        },
        {
            name: 'kcal',
            type: 'input',
            title: 'Kcal'
        },
        {
            name: 'carbs',
            type: 'input',
            title: 'Carbs'
        },
        {
            name: 'fat',
            type: 'input',
            title: 'Fat'
        },
        {
            name: 'protein',
            type: 'input',
            title: 'Protein'
        },
        {
            name: 'fibre',
            type: 'input',
            title: 'Fibre'
        },
        {
            name: 'water',
            type: 'input',
            title: 'Water'
        },
        {
            name: 'ash',
            type: 'input',
            title: 'Ash'
        },
        {
            name: 'sugars',
            type: 'input',
            title: 'Sugars'
        },
        {
            name: 'sumSaturated',
            type: 'input',
            title: 'Sum Saturated'
        },
        {
            name: 'sumMonounsaturated',
            type: 'input',
            title: 'Sum Monounsaturated'
        },
        {
            name: 'sumPolyunsaturated',
            type: 'input',
            title: 'Sum Polyunsaturated'
        },
        {
            name: 'cholesterol',
            type: 'input',
            title: 'Cholesterol'
        },
        {
            name: 'retinol',
            type: 'input',
            title: 'Retinol'
        },
        {
            name: 'vitaminA',
            type: 'input',
            title: 'VitaminA'
        },
        {
            name: 'bKaroten',
            type: 'input',
            title: 'BKaroten'
        },
        {
            name: 'vitaminD',
            type: 'input',
            title: 'VitaminD'
        },
        {
            name: 'vitaminE',
            type: 'input',
            title: 'VitaminE'
        },
        {
            name: 'vitaminK',
            type: 'input',
            title: 'VitaminK'
        },
        {
            name: 'tiamin',
            type: 'input',
            title: 'Tiamin'
        },
        {
            name: 'riboflavin',
            type: 'input',
            title: 'Tibo flavin'
        },
        {
            name: 'vitaminC',
            type: 'input',
            title: 'VitaminC'
        },
        {
            name: 'niacin',
            type: 'input',
            title: 'Niacin'
        },
        {
            name: 'vitaminB6',
            type: 'input',
            title: 'VitaminB6'
        },
        {
            name: 'vitaminB12',
            type: 'input',
            title: 'VitaminB12'
        },
        {
            name: 'folicAcid',
            type: 'input',
            title: 'Folic Acid'
        },
        {
            name: 'phosphorus',
            type: 'input',
            title: 'Phosphorus'
        },
        {
            name: 'iron',
            type: 'input',
            title: 'Iron'
        },
        {
            name: 'calcium',
            type: 'input',
            title: 'Calcium'
        },
        {
            name: 'potassium',
            type: 'input',
            title: 'Potassium'
        },
        {
            name: 'copper',
            type: 'input',
            title: 'Copper'
        },
        {
            name: 'magnesium',
            type: 'input',
            title: 'Magnesium'
        },
        {
            name: 'sodium',
            type: 'input',
            title: 'Sodium'
        },
        {
            name: 'selenium',
            type: 'input',
            title: 'Selenium'
        },
        {
            name: 'zink',
            type: 'input',
            title: 'Zink'
        },
        {
            name: 'source',
            type: 'input',
            title: 'Source'
        },
        {
            name: 'comment',
            type: 'input',
            title: 'Comment'
        },
        {
            name: 'active',
            type: 'checkbox',
            title: 'Active'
        },
      ]
}