export default {
  fields: [
    {
      name: 'dateStarted',
      type: 'input',
      title: 'Date Started',
    },
    {
      name: 'onDemand',
      type: 'checkbox',
      title: 'On Demand',
    },
    {
      name: 'pushNotice',
      type: 'checkbox',
      title: 'Push Notice',
    },
    {
      name: 'drugId',
      type: 'select',
      data: 'drugs',
      title: 'Drug',
    }
  ],
  dependencies: [ 
    {
      name: 'drugs',
      labelField: 'labelSubTitle',
    }
  ]
};