export default {
  fields: [
    {
      name: 'steps',
      type: 'input',
      title: 'Steg',
    },
    {
      name: 'training',
      type: 'input',
      title: 'Training',
    },
    {
      name: 'restingHeartRate',
      type: 'input',
      title: 'Hjärtfrekvens',
    },
    {
      name: 'stressSmartWatch',
      type: 'input',
      title: 'Stress from smartwatch',
    },
    {
      name: 'sleepSmartWatch',
      type: 'input',
      title: 'Sleep from smartwatch',
    },
    {
      name: 'date',
      type: 'input',
      title: 'Date',
    },
  ],
} 