export default {
    fields: [
        {
            name: 'amount',
            type: 'input',
            title: 'Amount'
        },
        {
            name: 'label',
            type: 'input',
            title: 'Label'
        },
        {
            name: 'serving',
            type: 'input',
            title: 'Serving'
        },
        {
            name: 'unit',
            type: 'input',
            title: 'Unit'
        },
        {
            name: 'variant',
            type: 'input',
            title: 'Variant'
        },
      ]
};