export default {
  fields: [
    {
      name: 'systolic',
      type: 'input',
      title: 'Systolic',
    },
    {
      name: 'diastolic',
      type: 'input',
      title: 'Diastolic',
    },
    {
      name: 'date',
      type: 'input',
      title: 'Date',
    },
  ],
} 