export { default as CreateActivityEntryMutation } from './CreateActivityEntryMutation';
export { default as CreateActivityMutation } from './CreateActivityMutation';
export { default as CreateAnthropometryMutation } from './CreateAnthropometryMutation';
export { default as CreateBloodPressureMutation } from './CreateBloodPressureMutation';
export { default as CreateCalorieMutation } from './CreateCalorieMutation';
export { default as CreateCholesterolMutation } from './CreateCholesterolMutation';
export { default as CreateDiabetesMutation } from './CreateDiabetesMutation';
export { default as CreateDrugEntryMutation } from './CreateDrugEntryMutation';
export { default as CreateExerciseMutation } from './CreateExerciseMutation';
export { default as CreateFoodEntryMutation } from './CreateFoodEntryMutation';
export { default as CreateFoodMutation } from './CreateFoodMutation';
export { default as CreateGlucoseDeviceMutation } from './CreateGlucoseDeviceMutation';
export { default as CreateGlucoseManualMutation } from './CreateGlucoseManualMutation';
export { default as CreateHealthMarkerMonitorIntervalMutation } from './CreateHealthMarkerMonitorIntervalMutation';
export { default as CreateHealthMarkerMonitorMutation } from './CreateHealthMarkerMonitorMutation';
export { default as CreateInsulinInjectionMutation } from './CreateInsulinInjectionMutation';
export { default as CreateKidneyMutation } from './CreateKidneyMutation';
export { default as CreateLifestyleMutation } from './CreateLifestyleMutation';
export { default as CreateMedicineMutation } from './CreateMedicineMutation';
export { default as CreateNoteMutation } from './CreateNoteMutation';
export { default as CreateRecipeMutation } from './CreateRecipeMutation';
export { default as CreateStepMutation } from './CreateStepMutation';

export { default as DeleteActivityEntryMutation } from './DeleteActivityEntryMutation';
export { default as DeleteActivityMutation } from './DeleteActivityMutation';
export { default as DeleteAnthropometryMutation } from './DeleteAnthropometryMutation';
export { default as DeleteBloodPressureMutation } from './DeleteBloodPressureMutation';
export { default as DeleteCalorieMutation } from './DeleteCalorieMutation';
export { default as DeleteCholesterolMutation } from './DeleteCholesterolMutation';
export { default as DeleteDiabetesMutation } from './DeleteDiabetesMutation';
export { default as DeleteDrugEntryMutation } from './DeleteDrugEntryMutation';
export { default as DeleteExerciseMutation } from './DeleteExerciseMutation';
export { default as DeleteFoodEntryMutation } from './DeleteFoodEntryMutation';
export { default as DeleteFoodMutation } from './DeleteFoodMutation';
export { default as DeleteFoodVariantMutation } from './DeleteFoodVariantMutation';
export { default as DeleteGlucoseDeviceMutation } from './DeleteGlucoseDeviceMutation';
export { default as DeleteGlucoseManualMutation } from './DeleteGlucoseManualMutation';
export { default as DeleteHealthMarkerMonitorIntervalMutation } from './DeleteHealthMarkerMonitorIntervalMutation';
export { default as DeleteHealthMarkerMonitorMutation } from './DeleteHealthMarkerMonitorMutation';
export { default as DeleteInsulinInjectionMutation } from './DeleteInsulinInjectionMutation';
export { default as DeleteKidneyMutation } from './DeleteKidneyMutation';
export { default as DeleteLifestyleMutation } from './DeleteLifestyleMutation';
export { default as DeleteMedicineMutation } from './DeleteMedicineMutation';
export { default as DeleteNoteMutation } from './DeleteNoteMutation';
export { default as DeleteRecipeMutation } from './DeleteRecipeMutation';
export { default as DeleteStepMutation } from './DeleteStepMutation';

export { default as UpdateActivityEntryMutation } from './UpdateActivityEntryMutation';
export { default as UpdateActivityMutation } from './UpdateActivityMutation';
export { default as UpdateAnthropometryMutation } from './UpdateAnthropometryMutation';
export { default as UpdateBloodPressureMutation } from './UpdateBloodPressureMutation';
export { default as UpdateCalorieMutation } from './UpdateCalorieMutation';
export { default as UpdateCholesterolMutation } from './UpdateCholesterolMutation';
export { default as UpdateDiabetesMutation } from './UpdateDiabetesMutation';
export { default as UpdateDrugEntryMutation } from './UpdateDrugEntryMutation';
export { default as UpdateExerciseMutation } from './UpdateExerciseMutation';
export { default as UpdateFoodEntryMutation } from './UpdateFoodEntryMutation';
export { default as UpdateFoodMutation } from './UpdateFoodMutation';
export { default as UpdateFoodVariantMutation } from './UpdateFoodVariantMutation';
export { default as UpdateGlucoseDeviceMutation } from './UpdateGlucoseDeviceMutation';
export { default as UpdateGlucoseManualMutation } from './UpdateGlucoseManualMutation';
export { default as UpdateHealthMarkerMonitorIntervalMutation } from './UpdateHealthMarkerMonitorIntervalMutation';
export { default as UpdateHealthMarkerMonitorMutation } from './UpdateHealthMarkerMonitorMutation';
export { default as UpdateInsulinInjectionMutation } from './UpdateInsulinInjectionMutation';
export { default as UpdateKidneyMutation } from './UpdateKidneyMutation';
export { default as UpdateLifestyleMutation } from './UpdateLifestyleMutation';
export { default as UpdateMedicineMutation } from './UpdateMedicineMutation';
export { default as UpdateNoteMutation } from './UpdateNoteMutation';
export { default as UpdateRecipeMutation } from './UpdateRecipeMutation';
export { default as UpdateSharingMutation } from './UpdateSharingMutation';
export { default as UpdateStepMutation } from './UpdateStepMutation';
export { default as UpdateUserMutation } from './UpdateUserMutation';
export { default as UpdateUserBaselineMutation } from './UpdateUserBaselineMutation';
