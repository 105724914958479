import {
  ActivityEntryTableQuery,
  AnthropometryTableQuery,
  BloodPressureTableQuery,
  CalorieTableQuery,
  CholesterolTableQuery,
  DiabetesTableQuery,
  DrugEntryTableQuery,
  ExerciseTableQuery,
  FoodEntryTableQuery,
  GlucoseDeviceTableQuery,
  GlucoseManualTableQuery,
  InsulinInjectionTableQuery,
  KidneyTableQuery,
  LifestyleTableQuery,
  NoteTableQuery,
  StepTableQuery,
  UserBaselineFormQuery,
  UserFormQuery,
} from '../queries';

export const queryList = {
  'activityEntryTable': ActivityEntryTableQuery,
  'anthropometryTable': AnthropometryTableQuery,
  'bloodPressureTable': BloodPressureTableQuery,
  'calorieTable': CalorieTableQuery,
  'cholesterolTable': CholesterolTableQuery,
  'diabetesTable': DiabetesTableQuery,
  'drugEntryTable': DrugEntryTableQuery,
  'exerciseTable': ExerciseTableQuery,
  'foodEntryTable': FoodEntryTableQuery,
  'glucoseDeviceTable': GlucoseDeviceTableQuery,
  'glucoseManualTable': GlucoseManualTableQuery,
  'insulinInjectionTable': InsulinInjectionTableQuery,
  'kidneyTable': KidneyTableQuery,
  'lifestyleTable': LifestyleTableQuery,
  'noteTable': NoteTableQuery,
  'stepTable': StepTableQuery,
  'userBaselineForm': UserBaselineFormQuery,
  'userForm': UserFormQuery,
};