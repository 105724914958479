export default {
  fields: [
    {
      name: 'creatinine',
      type: 'input',
      title: 'Kreatinin',
    },
    {
      name: 'ualbkrea',
      type: 'input',
      title: 'U-albumin/U-kreatinin',
    },
    {
      name: 'egfr',
      type: 'input',
      title: 'eGFR',
    },
    {
      name: 'date',
      type: 'input',
      title: 'Date',
    },
  ],
} 