import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../relay-environment";

const mutation = graphql`
  mutation CreateMedicineMutation($input: NewDrugInput!) {
    createDrug(newDrugInput: $input) {
      _id: id
    }
  }
`;

export default function CreateMedicineMutation(input) {
  // flow-disable
  return new Promise((resolve, reject) => {
    input = {
      atc: input.atc,
      labelTitle: input.labelTitle,
      labelSubTitle: input.labelSubTitle,
      activeSubstance: input.activeSubstance,
      delivery: input.delivery,
      dose: input.dose,
      icon: input.icon == undefined ? "missing" : input.icon,
      manufacturer: input.manufacturer,
    };
    const variables = {
      input,
    };
    commitMutation(environment, {
      mutation,
      variables,
      onError: (err) => reject(err),
    });
  });
}
