import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { DropdownItems } from "../../../../../constants";

import { GreenText, GrayText } from "../../../../../components";
import i18n from "../../../../../i18n";

const getLabel = (index, dropdown) => {
  var item = dropdown.find((element) => element.value === index);
  if (!item) {
    return "no";
  } else {
    return item.label;
  }
};

const mapDropdownListWithObject = (dropdownList, inputObject) => {
  const x = new Map();
  for (let i = 0; i < dropdownList.length; i++) {
    const element = dropdownList[i];
    x.set(element.label, false);
  }
  for (const element in inputObject) {
    if (x.has(element)) {
      x.set(element, inputObject[element]);
    }
  }
  return x;
};

const useStyles = makeStyles((theme) => ({
  root: {},
  label: {
    width: 200,
    marginRight: "10%",
  },
  row: {
    display: "flex",
    borderBottom: "solid",
    borderBottomStyle: "ridge",
  },
}));

const ProfileComponent = (props) => {
  const classes = useStyles(props);
  const [state, setState] = useState();
  const { user, anthropometries, diabetes, lifestyles } = props;

  // Without checking user && user.birthDate it causes a crash
  var tempDate = user && user.birthDate;
  var date = new Date(tempDate);

  var activity = getLabel(
    lifestyles && lifestyles.exerciseLevel &&
      lifestyles.filter((lifestyle) => lifestyle.exerciseLevel)[0]
        .exerciseLevel,
    DropdownItems.ActivityLevelDropdown
  );

  var diabetesLabel = getLabel(
    diabetes && diabetes[0] && diabetes[0].diabetesStatus,
    DropdownItems.DiabetesDropdown
  );
  var diabetesDurationLabel = getLabel(
    lifestyles && lifestyles[0] && lifestyles[0].duration,
    DropdownItems.DiabetesDurationDropdown
  );
  var diabetesTreatmentLabel = getLabel(
    user && user.userBaseline && user.userBaseline.typeOfTreatment,
    DropdownItems.DiabetesTreatmentDropdown
  );
  var diabetesWorryLabel = getLabel(
    user && user.userBaseline && user.userBaseline.worriedLevel,
    DropdownItems.DiabetesWorryDropdown
  );
  var diet = getLabel(
    lifestyles && lifestyles.dietType &&
      lifestyles.filter((lifestyle) => lifestyle.dietType)[0].dietType,
    DropdownItems.DietDropdown
  );
  var listOfCausePoorControl = mapDropdownListWithObject(
    DropdownItems.DiabetesCausePoorControlDropdown,
    user && user.userBaseline
  );
  var listOfAdditionalHelp = mapDropdownListWithObject(
    DropdownItems.DiabetesAdditionalHelp,
    user && user.userBaseline
  );

  return (
    <div className={classes.root}>
      <GreenText>{i18n.t("Profile")}</GreenText>
      <Box marginTop="1rem">
        <div className={classes.row}>
          <GrayText className={classes.label}>{i18n.t("email")}:</GrayText>
          <GrayText>{user && user.email}</GrayText>
        </div>
        <div className={classes.row}>
          <GrayText className={classes.label}>
            {i18n.t("Mobile number")}:
          </GrayText>
          <GrayText>{user && user.cellPhone}</GrayText>
        </div>
        <div className={classes.row}>
          <GrayText className={classes.label}>{i18n.t("Sex")}:</GrayText>
          <GrayText>{user && user.userBaseline && user.userBaseline.sex}</GrayText>
        </div>
        <div className={classes.row}>
          <GrayText className={classes.label}>{i18n.t("Birthdate")}:</GrayText>
          <GrayText>{date.toLocaleDateString("en-CA")}</GrayText>
        </div>
        <div className={classes.row}>
          <GrayText className={classes.label}>{i18n.t("Height")}:</GrayText>
          <GrayText>{anthropometries && anthropometries[0] && anthropometries[0].height}</GrayText>
        </div>
        <div className={classes.row}>
          <GrayText className={classes.label}>{i18n.t("Weight")}:</GrayText>
          <GrayText>
            {anthropometries && anthropometries[0] &&
              anthropometries.filter((anthropometry) => anthropometry.weight)[0]
                .weight}
          </GrayText>
        </div>
        <div className={classes.row}>
          <GrayText className={classes.label}>
            {i18n.t("Desired weight")}:
          </GrayText>
          <GrayText>{user && user.userBaseline && user.userBaseline.aimGoalWeight}</GrayText>
        </div>
        <div className={classes.row}>
          <GrayText className={classes.label}>
            {i18n.t("diabetes_type")}:
          </GrayText>
          <GrayText>{diabetesLabel}</GrayText>
        </div>
        <div className={classes.row}>
          <GrayText className={classes.label}>
            {i18n.t("diabetes_duration")}:
          </GrayText>
          <GrayText>{diabetesDurationLabel}</GrayText>
        </div>
        <div className={classes.row}>
          <GrayText className={classes.label}>
            {i18n.t("diabetes_treatment")}:
          </GrayText>
          <GrayText>{diabetesTreatmentLabel}</GrayText>
        </div>
        <div className={classes.row}>
          <GrayText className={classes.label}>
            {i18n.t("worry_level")}:
          </GrayText>
          <GrayText>{diabetesWorryLabel}</GrayText>
        </div>
        <div className={classes.row}>
          <GrayText className={classes.label}>
            {i18n.t("Activity level")}:
          </GrayText>
          <GrayText>{activity}</GrayText>
        </div>
        <div className={classes.row}>
          <GrayText className={classes.label}>
            {i18n.t("Preferred diet")}:
          </GrayText>
          <GrayText>{diet}</GrayText>
        </div>
        {[...listOfCausePoorControl].map((element) => {
          return (
            <div className={classes.row}>
              <GrayText className={classes.label}>
                {i18n.t(element[0])}:
              </GrayText>
              <GrayText>
                {element[1] === true ? i18n.t("Yes") : i18n.t("No")}
              </GrayText>
            </div>
          );
        })}
        {[...listOfAdditionalHelp].map((element) => {
          return (
            <div className={classes.row}>
              <GrayText className={classes.label}>
                {i18n.t(element[0])}:
              </GrayText>
              <GrayText>
                {element[1] === true ? i18n.t("Yes") : i18n.t("No")}
              </GrayText>
            </div>
          );
        })}

        <div className={classes.row}>
          <GrayText className={classes.label}>
            {i18n.t("complications")}
          </GrayText>
          <GrayText>
            {lifestyles && lifestyles.complications
              ? i18n.t("Yes")
              : i18n.t("No")}
          </GrayText>
        </div>

        <div className={classes.row}>
          <GrayText className={classes.label}>{i18n.t("Smartwatch")}:</GrayText>
          <GrayText>
            {user && user.userBaseline && user.userBaseline.useSmartWatch
              ? i18n.t("Yes")
              : i18n.t("No")}
          </GrayText>
        </div>
        <div className={classes.row}>
          <GrayText className={classes.label}>
            {i18n.t("Insulin Pump")}:
          </GrayText>
          <GrayText>
            {user && user.userBaseline && user.userBaseline.usePump ? i18n.t("Yes") : i18n.t("No")}
          </GrayText>
        </div>
        <div className={classes.row}>
          <GrayText className={classes.label}>{i18n.t("CGM")}:</GrayText>
          <GrayText>
            {user && user.userBaseline && user.userBaseline.useCgm ? i18n.t("Yes") : i18n.t("No")}
          </GrayText>
        </div>
        <div className={classes.row}>
          <GrayText className={classes.label}>
            {i18n.t("Glucose strips")}:
          </GrayText>
          <GrayText>
            {user && user.userBaseline &&
              (user.userBaseline.useStrips ? i18n.t("Yes") : i18n.t("No"))}
          </GrayText>
        </div>
      </Box>
    </div>
  );
};

export default ProfileComponent;
