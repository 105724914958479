export default {
  fields: [
    {
      name: 'date',
      type: 'input',
      title: 'DateTime',
    },
    {
      name: 'hdlChol',
      type: 'input',
      title: 'HDL Chol',
    },
    {
      name: 'ldlChol',
      type: 'input',
      title: 'LDL Chol',
    },
    {
      name: 'totalChol',
      type: 'input',
      title: 'Total Chol',
    },
    {
      name: 'triglycerides',
      type: 'input',
      title: 'Triglycerides',
    },
  ]
};