export default {
  fields: [
    {
      name: 'title',
      type: 'input',
      title: 'Title',
    },
    {
      name: 'text',
      type: 'input',
      title: 'Text',
    },
    {
      name: 'createdAt',
      type: 'input',
      title: 'Date',
    },
  ]
};