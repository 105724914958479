import { Images } from '../../styles';
import i18n from '../../i18n';

export const ADMIN_MENU_ITEMS = [
  {
    link: 'users',
    icon: Images.ICON_USER,
    text: i18n.t('User'),
  },
  {
    link: 'recipes',
    icon: Images.ICON_RECEIPE,
    text: i18n.t('Recipe'),
  },
  {
    link: 'foods',
    icon: Images.ICON_FOOD,
    text: i18n.t('Food'),
  },
  {
    link: 'activities',
    icon: Images.ICON_ACTIVITY,
    text: i18n.t('Physical activity'),
  },
  {
    link: 'drugs',
    icon: Images.ICON_MEDICINE,
    text: i18n.t('Medicine'),
  },
];

export const PROVIDER_MENU_ITEMS = [
  {
    link: 'overview',
    icon: Images.ICON_USER,
    text: i18n.t('Overview'),
  },
  {
    link: 'account',
    icon: Images.ICON_RECEIPE,
    text: i18n.t('My account'),
  },
];